import { useEffect, useRef, useState } from 'react'
import AddFormWrapper from 'components/addForms/AddFormWrapper'
import './AddFieldInput.scss'

interface Props {
  title: string;
  addTooltip: string;
  cancelTooltip: string;
  handleAdd: (value: string) => () => void;
  handleDelete: () => () => void;
}

export default function AddFieldInput({
  title, addTooltip, cancelTooltip, handleAdd, handleDelete,
}: Props) {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <AddFormWrapper
      className="add-field-input"
      addTooltip={addTooltip}
      cancelTooltip={cancelTooltip}
      handleAdd={handleAdd(inputValue)}
      handleDelete={handleDelete()}
    >
      <div className="input">
        <span>{title}</span>
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleChange}
        />
      </div>
    </AddFormWrapper>
  )
}
