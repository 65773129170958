import terms from 'assets/terms'
import {
  Button, Loader, ModalAddTeam, modalSignal,
} from 'components'
import TeamAccordion from 'components/teamAccordion/TeamAccordion'
import { useEffect } from 'react'
import { getTeams } from 'reducers/manage/thunks'
import { useAppDispatch, useAppSelector, MouseSensor, KeyboardSensor, useCerbereUsers } from 'utils'
import { handleChangeAgentTeam, handlePostNewTeam } from 'services'
import { DndContext, DragEndEvent, useSensor, useSensors } from '@dnd-kit/core'
import './TeamTab.scss'

interface Props {
  technicalCenterId: string;
}

const localTerms = terms.Pages.Manage.teams
export default function TeamTab({ technicalCenterId }: Props) {
  const dispatch = useAppDispatch()
  const { teams, teamsLoading } = useAppSelector(state => state.manage)
  useCerbereUsers(teams.flatMap(team => team.agents))
  const mouseSensor = useSensor(MouseSensor)
  const keyboardSensor = useSensor(KeyboardSensor)
  const sensors = useSensors(mouseSensor, keyboardSensor)

  useEffect(() => {
    dispatch(getTeams(technicalCenterId))
  }, [])

  const handleAddTeam = () => {
    modalSignal.value = (
      <ModalAddTeam
        title={localTerms.add}
        label={localTerms.teamName}
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handlePostNewTeam(technicalCenterId, () => { modalSignal.value = null })}
      />
    )
  }

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const currentTeamId = active?.data?.current?.teamId
    const targetTeamId = over?.data?.current?.teamId
    const agentId = active?.data?.current?.agentId

    if (!currentTeamId || !targetTeamId || !agentId) return
    if (currentTeamId === targetTeamId) return

    handleChangeAgentTeam(technicalCenterId, currentTeamId, targetTeamId, agentId)
  }

  if (teamsLoading) {
    return (
      <Loader message={localTerms.loading} standalone />
    )
  }
  return (
    <div className="team-tab">
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        {teams.map(team => (
          <TeamAccordion key={team.id} id={team.id} technicalCenterId={technicalCenterId} team={team} />
        ))}
      </DndContext>
      <Button text={localTerms.add} onClick={handleAddTeam} />
    </div>
  )
}
