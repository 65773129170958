import { ReactNode } from 'react'
import './ActionWrapper.scss'

type Action = {
  title: string
  available: boolean
  onClick: () => void
}

interface Props {
  actions: Action[]
  children: ReactNode

}

export default function ActionWrapper({
  actions,
  children,
}: Props) {
  return (
    <div className="action-wrapper">
      {children}
      {actions.filter(action => action.available).map(action => (
        <button
          key={action.title}
          type="button"
          className="action-wrapper-button"
          onClick={action.onClick}
          disabled={!action.available}
        >
          {action.title}
        </button>
      ))}
    </div>
  )
}
