import { FormValues } from 'components/modals/addMissionTC/formHandlers'
import { MissionTC } from 'types'

// --- Remove empty values before submit ---
const cleanFormValues = (values: FormValues) => {
  const cleanedValues: Partial<MissionTC> = Object.keys(values).reduce((acc, key) => {
    if (values[key as keyof typeof values] !== ''
      && !(key === 'missions_supplementaires' && Array.isArray(values[key]) && values[key].length === 0)) {
      acc[key] = values[key]
    }
    return acc
  }, {})
  return cleanedValues
}
// ------

export {
  cleanFormValues,
}
