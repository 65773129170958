import { useEffect } from 'react'
import terms from 'assets/terms'
import { Loader } from 'components'
import { getTechnicalCenters } from 'reducers/params/thunks'
import { useAppDispatch, useAppSelector } from 'utils'

import ConstructionsForm from './constructions/ConstructionsForm'
import DirectionsForm from './directions/DirectionsForm'
import './TechnicalCentersForm.scss'

const localTerms = terms.Pages.Params.technicalCenters
export default function TechnicalCentersForm() {
  const dispatch = useAppDispatch()
  const { technicalCenters, technicalCentersLoading } = useAppSelector(state => state.params)

  useEffect(() => {
    dispatch(getTechnicalCenters())
  }, [])

  if (technicalCentersLoading) {
    return (
      <Loader message={localTerms.loading} standalone />
    )
  }
  return (
    <div className="technical-centers-form">
      {technicalCenters.map((tc, tcIndex) => (
        <div className="technical-center" key={tc.id}>
          <div className="technical-center-title">
            <span>{`${localTerms.technicalCenterTitle}${tcIndex + 1}`}</span>
            <input type="text" value={tc.libelle} readOnly />
          </div>
          <ConstructionsForm technicalCenter={tc} />
          <DirectionsForm technicalCenter={tc} />
        </div>
      ))}
    </div>
  )
}
