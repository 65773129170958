import { get, patch } from '@osrdata/app_core/dist/requests'
import { MissionAgent, MissionTC, MissionTeam, PatchFiltersParams, TCFilter } from 'types'
import { createApiThunk } from 'utils'

const getMissionTeams = createApiThunk(
  'r2d2/getMissionTeams',
  (technicenterId: string) => get<MissionTeam[]>(`/r2d2/technicentres/${technicenterId}/equipe/`),
)

const getMissionAgents = createApiThunk(
  'r2d2/getMissionAgents',
  (technicenterId: string) => get<MissionAgent[]>(`/r2d2/technicentres/${technicenterId}/agent/`),
)

const getTechnicalCenter = createApiThunk(
  'r2d2/getTechnicalCenter',
  (technicenterId: string) => get<[]>(`/r2d2/technicentres/${technicenterId}/`),
)

const getMissionsTC = createApiThunk(
  'r2d2/getTechnicalCenter',
  (params : { technicenterId: string, ordering: string}) => Promise.allSettled([
    get<MissionTC[]>(`/r2d2/technicentres/${params.technicenterId}/missions/`, { ordering: params.ordering }),
    // eslint-disable-next-line max-len
    get<MissionTC[]>(`/r2d2/technicentres/${params.technicenterId}/missions/preaffectee/`, { ordering: params.ordering }),
  ]),
)

const getTCFilters = createApiThunk(
  'r2d2/getTCFilters',
  (technicenterId: string) => get<TCFilter>(`/r2d2/technicentres/${technicenterId}/filters/`),
)

const patchTCFilters = createApiThunk(
  'r2d2/patchTCFilters',
  (params: PatchFiltersParams) => patch<TCFilter>(
    `/r2d2/technicentres/${params.technicenterId}/filters/patch-filters/`,
    params.filters,
  ),
)

export {
  getMissionTeams,
  getMissionAgents,
  getTechnicalCenter,
  getMissionsTC,
  getTCFilters,
  patchTCFilters,
}
