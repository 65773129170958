import terms from 'assets/terms'
import { useCallback, useEffect } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setActiveRole } from 'reducers/app'
import { getUsersFromIds } from 'reducers/cerbere/thunks'
import type { AppDispatch, RootState } from 'reducers/store'
import { routes } from 'services'
import { ActiveRole, AppRoles } from 'types'
import { ADMIN_PERM } from 'utils'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useCerbereUsers = (cerbereIds?: string[], unknownUserText: string = terms.Common.unknownUser) => {
  const dispatch = useAppDispatch()
  const { users, loadingUsers, unknownUsers } = useAppSelector(state => state.cerbere)

  useEffect(() => {
    if (!cerbereIds) return
    const missingUsers = cerbereIds.filter(id => !users.find(user => user.id === id))
      .filter(id => !unknownUsers.includes(id))
    if (missingUsers.length > 0) {
      dispatch(getUsersFromIds(missingUsers))
    }
  }, [cerbereIds])

  const getCerbereUser = useCallback((id: string) => users.find(user => user.id === id), [users])

  const getUserName = useCallback((id: string) => {
    const user = getCerbereUser(id)
    if (user?.lastName && user?.firstName) return `${user.lastName.toUpperCase()} ${user.firstName}`
    if (user?.username) return user.username.toUpperCase()
    return unknownUserText
  }, [users])
  return { getCerbereUser, loadingUsers, getUserName }
}

export const usePageRoles = (roles: AppRoles[]) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { appPermissions } = useAppSelector(state => state.user)
  const { activeRole, userRoles } = useAppSelector(state => state.app)

  useEffect(() => {
    const isAdmin = appPermissions.includes(ADMIN_PERM)
    if (isAdmin && roles.includes(AppRoles.admin)) {
      dispatch(setActiveRole({ role: AppRoles.admin }))
      return
    }
    if (activeRole && roles.includes(activeRole.role)) return
    const possibleActiveRole: ActiveRole[] = []
    roles.forEach(role => {
      userRoles.filter(r => r.roles.includes(role)).forEach(roleData => {
        possibleActiveRole.push({
          role,
          technicenterId: roleData.id,
          technicenterName: roleData.libelle,
        })
      })
    })
    if (possibleActiveRole.length === 1) {
      dispatch(setActiveRole(possibleActiveRole[0]))
    } else {
      dispatch(setActiveRole(null))
      navigate(routes.home)
    }
  }, [])
}
