import { Close } from '@mui/icons-material'
import terms from 'assets/terms'
import {
  Button, ButtonStyle,
} from 'components'
import { cleanFormValues } from 'utils'
import { MissionStatus, MissionTC } from 'types'
import { useHandlers, useRequiredFields } from './formHandlers'

import './ModalAddMissionTC.scss'
import MissionTCFields from './fields/MissionTCFields'

interface Props {
  title: string;
  handleClose: () => void;
  handleValidate: (missionTC: Partial<MissionTC>) => () => void;
  technicalCenterId: string;
}

const localTerms = terms.Modals.AddMissionTC
export default function ModalAddMissionTC({
  title,
  handleClose,
  handleValidate,
  technicalCenterId,
}: Props) {
  const {
    formValues,
    handleInputChange,
    handleCheckboxChange,
    handleTimeChange,
    handleMultiSelectChange,
  } = useHandlers()
  const requiredFields = useRequiredFields(formValues)
  const isFormValid = requiredFields.every(field => formValues[field as keyof typeof formValues] !== '')

  // --- Change statut value based on submit button ---
  const handleSubmitWithAffectee = () => {
    const updatedFormValues = {
      ...formValues,
      statut: MissionStatus.assigned,
    }
    handleValidate(cleanFormValues(updatedFormValues))()
  }
  // ------

  return (
    <div className="modal-add-missiontc">
      <div className="header">
        <h2>{title}</h2>
        <button type="button" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <MissionTCFields
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
        handleTimeChange={handleTimeChange}
        handleMultiSelectChange={handleMultiSelectChange}
        technicalCenterId={technicalCenterId}
        requiredFields={requiredFields}
      />

      <div className="actions">
        <Button
          text={localTerms.assignment}
          onClick={handleSubmitWithAffectee}
          style={ButtonStyle.light}
          disabled={!isFormValid || formValues.agent === ''}
        />
        <Button
          text={localTerms.preassignment}
          onClick={handleValidate(cleanFormValues(formValues))}
          disabled={!formValues.modele || !formValues.rame}
        />
      </div>
    </div>
  )
}
