import { useEffect, useRef } from 'react'
import './Checkbox.scss'

interface Props {
  id?: string;
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: () => void;
  readonly?: boolean;
  label?: string;
  size?: string
}

export default function Checkbox({ id, checked, indeterminate, onChange, readonly, label, size }: Props) {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = !checked && indeterminate
    }
  }, [indeterminate, checked])

  return (
    <div className="custom-checkbox">
      <input
        id={id}
        ref={ref}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        readOnly={readonly}
      />
      <span className="checkbox-checkmark" onClick={onChange} />
      <span className="label">{label}</span>
    </div>
  )
}
