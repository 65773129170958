import terms from 'assets/terms'
import { ParamsMission } from 'types'
import './MissionBooleanFields.scss'
import { ChangeEvent } from 'react'

interface Props {
  id: string
  mission: Omit<ParamsMission, 'id'>
  handleClickField: (field: string) => (e: ChangeEvent<HTMLInputElement>) => void
}

const localTerms = terms.Pages.Params.missions
export default function MissionBooleanFields({ id, mission, handleClickField }: Props) {
  return (
    <div className="mission-boolean-fields">
      <span>{localTerms.mandatoryFields}</span>
      <div className="fields">
        <div className="boolean">
          <input
            type="checkbox"
            id={`construction-start-${id}`}
            checked={mission.chantier_depart}
            onChange={handleClickField('chantier_depart')}
          />
          <label htmlFor={`construction-start-${id}`}>{localTerms.constructionStart}</label>
        </div>
        <div className="boolean">
          <input
            type="checkbox"
            id={`track-start-${id}`}
            checked={mission.voie_depart}
            onChange={handleClickField('voie_depart')}
          />
          <label htmlFor={`track-start-${id}`}>{localTerms.trackStart}</label>
        </div>
        <div className="boolean">
          <input
            type="checkbox"
            id={`construction-end-${id}`}
            checked={mission.chantier_arrivee}
            onChange={handleClickField('chantier_arrivee')}
          />
          <label htmlFor={`construction-end-${id}`}>{localTerms.constructionEnd}</label>
        </div>
        <div className="boolean">
          <input
            type="checkbox"
            id={`track-end-${id}`}
            checked={mission.voie_arrivee}
            onChange={handleClickField('voie_arrivee')}
          />
          <label htmlFor={`track-end-${id}`}>{localTerms.trackEnd}</label>
        </div>
        <div className="boolean">
          <input
            type="checkbox"
            id={`direction-${id}`}
            checked={mission.sens_depart}
            onChange={handleClickField('sens_depart')}
          />
          <label htmlFor={`direction-${id}`}>{localTerms.direction}</label>
        </div>
        <div className="boolean">
          <input
            type="checkbox"
            id={`via-${id}`}
            checked={mission.via}
            onChange={handleClickField('via')}
          />
          <label htmlFor={`via-${id}`}>{localTerms.via}</label>
        </div>
      </div>
    </div>
  )
}
