import { Button } from 'components'
import { signal } from '@preact/signals-react'
import 'moment/locale/fr'
import './NotificationsDrawer.scss'
import {
  Stack, Checkbox, Drawer, Box, Divider, FormControlLabel,
} from '@mui/material'
import { useAppSelector } from 'utils'
import * as moment from 'moment'

moment.locale('fr')

export const notificationsDrawerSignal = signal<boolean>(false)

const checkboxes = [
  {
    label: 'Création',
    color: '#3B38DA',
  },
  {
    label: 'Modification',
    color: '#FAB142',
  },
  {
    label: 'Refus / Suppression',
    color: '#DA4238',
  },
  {
    label: 'Validation',
    color: '#43CF7B',
  },
  {
    label: 'Archivage',
    color: '#3A4457',
  },
]
export default function NotificationsDrawer() {
  const { notifications } = useAppSelector(state => state.notifications)

  const toggleDrawer = (newOpen: boolean) => () => {
    notificationsDrawerSignal.value = newOpen
  }

  const DrawerList = (
    <Box role="presentation" p={3} borderRadius={5}>
      <Box mb={3} fontWeight={800} fontSize={20} borderRadius={5}>
        <span>NOTIFICATIONS</span>
      </Box>
      <Stack direction="row">
        {checkboxes.map(cb => (
          <FormControlLabel
            sx={{ color: cb.color }}
            key={cb.label}
            control={(
              <Checkbox
                defaultChecked
                size="small"
                sx={{
                  color: '#D7E1F3',
                  '&.Mui-checked': {
                    color: cb.color,
                  },
                }}
              />
            )}
            label={(
              <Box fontWeight={800} color={cb.color}>
                <span>{cb.label}</span>
              </Box>
)}
          />
        ))}
      </Stack>
      <Box mt={1} mb={3}>
        <Button text="Tout marquer comme lu" />
      </Box>

      {notifications.map(not => (
        <Stack key={not.timestamp}>
          <Divider sx={{ my: 2 }} />
          <Box fontSize={20}>
            Mission
            {' '}
            {not.numero}
            {' '}
            :
            {' '}
            {not.type}
            {' '}
            par le
            {' '}
            {not.origine}
          </Box>
          <Box fontSize={20} fontWeight={800}>
            {moment(not.timestamp).format('ddd DD MMM YYYY')}
            {' '}
            à
            {' '}
            {moment(not.timestamp).format('HH:mm')}
          </Box>
        </Stack>

      ))}
    </Box>
  )

  return (
    <div>
      <Drawer
        open={notificationsDrawerSignal.value}
        onClose={toggleDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: {
            marginTop: 18,
            borderTopLeftRadius: 8,
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  )
}
