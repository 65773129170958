import { get } from '@osrdata/app_core/dist/requests'
import { ParamsAdditionalMission, ParamsDefects, ParamsMission, ParamsTechnicalCenter } from 'types'
import { createApiThunk } from 'utils'

const getMissions = createApiThunk(
  'params/getMissions',
  () => get<ParamsMission[]>('/r2d2/mission-modele/'),

)

const getAdditionalMissions = createApiThunk(
  'params/getAdditionalMissions',
  () => get<ParamsAdditionalMission[]>('/r2d2/mission-supplementaire-modele/'),
)

const getTechnicalCenters = createApiThunk(
  'params/getTechnicalCenters',
  () => get<ParamsTechnicalCenter[]>('/r2d2/technicentres/'),
)

const getObservations = createApiThunk(
  'params/getObservations',
  () => get<ParamsDefects[]>('/r2d2/observation-rd/'),
)

const getDefects = createApiThunk(
  'params/getDefects',
  () => get<ParamsDefects[]>('/r2d2/defaut-rd/'),
)
export {
  getMissions,
  getAdditionalMissions,
  getTechnicalCenters,
  getObservations,
  getDefects,
}
