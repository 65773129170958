/* export utils here */
export * from './thunks'
export * from './hooks'
export * from './errors'
export * from './dnd'
export * from './missionTable'

export const APP_NAME = 'R2D2'
export const ACCESS_PERM = 'r2d2::ACCESS'
export const ADMIN_PERM = 'r2d2::ADMINISTRATEUR'
