import { Add, Close } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import './AddFormWrapper.scss'

interface Props {
  className: string;
  children: React.ReactNode;
  addTooltip: string;
  cancelTooltip: string;
  handleAdd: () => void;
  handleDelete: () => void;
}

export default function AddFormWrapper({
  className, children, addTooltip, cancelTooltip, handleAdd, handleDelete,
}: Props) {
  return (
    <div className={`add-form-wrapper ${className}`}>
      {children}
      <div className="actions">
        <Tooltip title={cancelTooltip} placement="top">
          <button
            type="button"
            className="cancel"
            onClick={handleDelete}
          >
            <Close />
          </button>
        </Tooltip>
        <Tooltip title={addTooltip} placement="top">
          <button
            type="button"
            className="add"
            onClick={handleAdd}
          >
            <Add />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}
