export enum AppRoles {
  admin = 'Administrateur',
  manager = 'gestionnaire',
  com = 'com',
  consultant = 'Consultant',
  RD = 'rd',
}

export type UserRoles = {
  id: string
  libelle: string
  roles: AppRoles[]
}

export type ActiveRole = {
  technicenterId: string
  technicenterName: string
  role: AppRoles
} | {
  role: AppRoles.admin
}

export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface AppState {
  error: null | undefined,
  fullscreen: boolean,
  displaySnackbar: boolean,
  snackbarMessage: string,
  snackbarSeverity: SnackbarSeverity,
  loadingUserRoles: boolean,
  userRoles: UserRoles[],
  activeRole: ActiveRole | null,
}
