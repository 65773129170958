import React from 'react'
import './SelectFormField.scss'

interface Option {
  id: string;
  libelle: string;
}

interface Props {
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  options: Option[];
  disabled?: boolean;
  required?: boolean;
  name: string;
  multiple?: boolean;
  disableUnselect?: boolean;
}

export default function SelectFormField({
  value,
  onChange,
  label,
  options,
  disabled,
  required,
  name,
  multiple,
  disableUnselect,
}: Props) {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e)
  }

  return (
    <div className="select-form-field">
      <span>
        {label}
        {required && <span style={{ color: '#DA4238' }}>*</span>}
      </span>
      <select
        value={value}
        onChange={handleSelectChange}
        disabled={disabled}
        name={name}
        multiple={multiple}
      >
        {!multiple && (<option disabled={disableUnselect} value="">Choisir une option</option>)}
        {options?.map(option => (
          <option
            key={option.id}
            value={option.id}
          >
            {option.libelle}
          </option>
        ))}
      </select>
    </div>
  )
}
