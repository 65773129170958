import { MouseEvent, ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { RootState } from 'reducers/store'
import { Item, MenuItemType } from '../Item'

import './MenuAccount.scss'

export default function AccountMenu(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const user = useSelector((state: RootState) => state.user)
  const isMenuOpen = Boolean(anchorEl)

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }

  return (
    <div>
      <Button onClick={handleButtonClick} endIcon={<KeyboardArrowDownIcon />}>
        {`${user.account.firstName} ${user.account.lastName}`}
      </Button>
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={() => handleMenuClose()}>
        {Object.values(MenuItemType).map(item => (<Item key={item} type={item} closeMenu={handleMenuClose} />))}
      </Menu>
    </div>
  )
}
