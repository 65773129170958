import { Close } from '@mui/icons-material'
import { Button, Checkbox } from 'components'
import { useState } from 'react'
import './ModalPatchMission.scss'

interface Props<T> {
  title: string
  handleClose: () => void
  handleValidate: (newValue: T[]) => () => void
  options: T[]
  selectedOptions: T[]
}

export default function ModalPatchMissionSelectField<T extends {id: string, libelle: string}>({
  title,
  handleClose,
  handleValidate,
  options,
  selectedOptions,
}: Props<T>) {
  const [value, setValue] = useState<T[]>(selectedOptions)
  return (
    <div className="modal-patch-mission">
      <div className="header">
        <h2>{title}</h2>
        <button type="button" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <div className="select-options">
        {options.map(option => {
          const isSelected = value.some(selectedOption => selectedOption.id === option.id)
          return (
            <div key={option.id} className="select-option">
              <Checkbox
                key={option.id}
                checked={isSelected}
                onChange={() => {
                  if (isSelected) {
                    setValue(value.filter(selectedOption => selectedOption.id !== option.id))
                  } else {
                    setValue([...value, option])
                  }
                }}
              />
              <span>{option.libelle}</span>
            </div>
          )
        })}
      </div>
      <div className="footer">
        <Button
          text="Valider les modifications"
          onClick={handleValidate(value)}
        />
      </div>
    </div>
  )
}
