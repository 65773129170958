import { ArrowForwardIos } from '@mui/icons-material'
import { useState } from 'react'
import BackButton from './BackButton'
import SelectForm from './SelectForm'

type Option = {
  id: string
  libelle: string
}

interface Props {
  title: string
  details: string
  options: Option[]
  selectedOptions: Option[]
  handleValidate: (options: Option[]) => () => void
  disabled?: boolean
}

export default function DataPanel({ title, details, options, selectedOptions, handleValidate, disabled }: Props) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="rd-mission-data-panel">
      <button type="button" onClick={() => setExpanded(true)} disabled={disabled}>
        <div className="text">
          <strong>{title}</strong>
          <span>{details}</span>
        </div>
        {!disabled && (
          <ArrowForwardIos />
        )}
      </button>
      {expanded && (
        <div className="expanded-data-panel">
          <BackButton onClick={() => setExpanded(false)} />
          <SelectForm
            title={title}
            options={options}
            selectedOptions={selectedOptions}
            handleValidate={handleValidate}
            handleClose={() => setExpanded(false)}
          />
        </div>
      )}
    </div>
  )
}
