import { deleteRequest, patch, post } from '@osrdata/app_core/dist/requests'
import terms from 'assets/terms'
import { setSnackbar } from 'reducers/app'
import { addCerbereUser } from 'reducers/cerbere'
import {
  addTeamMember,
  deleteCOM, deleteConsultant, deleteTeam, postNewCOM, postNewConsultant, postNewTeam,
  removeTeamMember,
} from 'reducers/manage'
import { store } from 'reducers/store'
import { CerbereUser, ManageTeam, SnackbarSeverity } from 'types'
import { parseErrorMessage } from 'utils'

export const handlePostNewCOM = (centerId: string, callback: () => void) => (user: CerbereUser) => () => post(
  `/r2d2/technicentres/${centerId}/coms/`,
  { cerbere_id: user.id },
).then(() => {
  callback()
  store.dispatch(postNewCOM(user.id))
  store.dispatch(addCerbereUser(user))
  store.dispatch(setSnackbar({ message: terms.Messages.success.manage.postCom, severity: SnackbarSeverity.SUCCESS }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.postCom)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleDeleteCOM = (centerId: string, comId: string, callback: () => void) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/coms/${comId}/`,
).then(() => {
  callback()
  store.dispatch(deleteCOM(comId))
  store.dispatch(setSnackbar({ message: terms.Messages.success.manage.deleteCom, severity: SnackbarSeverity.SUCCESS }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.deleteCom)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handlePostNewConsultant = (centerId: string, callback: () => void) => (user: CerbereUser) => () => post(
  `/r2d2/technicentres/${centerId}/consultants/`,
  { cerbere_id: user.id },
).then(() => {
  callback()
  store.dispatch(postNewConsultant(user.id))
  store.dispatch(addCerbereUser(user))
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.postConsultant,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.postConsultant)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleDeleteConsultant = (
  centerId: string,
  consultantId: string,
  callback: () => void,
) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/consultants/${consultantId}/`,
).then(() => {
  callback()
  store.dispatch(deleteConsultant(consultantId))
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.deleteConsultant,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.deleteConsultant)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handlePostNewTeam = (centerId: string, callback: () => void) => (teamName: string) => () => post(
  `/r2d2/technicentres/${centerId}/equipe/`,
  { libelle: teamName },
).then((newTeam: ManageTeam) => {
  store.dispatch(postNewTeam(newTeam))
  callback()
  store.dispatch(setSnackbar({ message: terms.Messages.success.manage.postTeam, severity: SnackbarSeverity.SUCCESS }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.postTeam)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleDeleteTeam = (centerId: string, teamId: string, callback: () => void) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/equipe/${teamId}/`,
).then(() => {
  store.dispatch(deleteTeam(teamId))
  callback()
  store.dispatch(setSnackbar({ message: terms.Messages.success.manage.deleteTeam, severity: SnackbarSeverity.SUCCESS }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.deleteTeam)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleAddMemberToTeam = (
  centerId: string,
  teamId: string,
  callback: () => void,
) => (user: CerbereUser) => () => post(
  `/r2d2/technicentres/${centerId}/agent/`,
  { cerbere_id: user.id, equipe: teamId },
).then(() => {
  store.dispatch(addTeamMember({ teamId, agentId: user.id }))
  store.dispatch(addCerbereUser(user))
  callback()
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.postTeamMember,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.postTeamMember)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleRemoveMemberFromTeam = (
  centerId: string,
  teamId: string,
  memberId: string,
  callback: () => void,
) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/agent/${memberId}/`,
).then(() => {
  store.dispatch(removeTeamMember({ teamId, agentId: memberId }))
  callback()
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.deleteTeamMember,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.deleteTeamMember)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleChangeAgentTeam = (
  centerId: string,
  currentTeamId: string,
  targetTeamId: string,
  agentId: string,
) => patch(`/r2d2/technicentres/${centerId}/agent/${agentId}/`, { equipe: targetTeamId }).then(() => {
  store.dispatch(removeTeamMember({ teamId: currentTeamId, agentId }))
  store.dispatch(addTeamMember({ teamId: targetTeamId, agentId }))
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.changeTeamMember,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.changeTeamMember)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})
