import terms from 'assets/terms'
import { ChangeEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'utils'
import { getMissions } from 'reducers/params/thunks'
import { ParamsMission } from 'types'
import { AsyncInput, Button, Loader, TooltipButton } from 'components'
import { Add, Delete } from '@mui/icons-material'
import { handleDeleteMission, handlePatchMission, handlePostNewMission } from 'services'
import AddMissionForm from './AddMissionForm'
import MissionBooleanFields from './MissionBooleanFields'
import './MissionsForm.scss'

const localTerms = terms.Pages.Params.missions
export default function MissionsForm() {
  const dispatch = useAppDispatch()
  const { missions, missionsLoading } = useAppSelector(state => state.params)
  const [addingMission, setAddingMission] = useState(false)

  useEffect(() => {
    dispatch(getMissions())
  }, [])

  const handleChangeMissionName = (id: string) => (value: string) => handlePatchMission(id, { libelle: value })
  const handleChangeDuration = (id: string) => (value: string) => handlePatchMission(
    id,
    { duree_theorique: value as unknown as number },
  )
  const handleChangeBooleanField = (mission: ParamsMission) => (field: string) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => handlePatchMission(
    mission.id,
    {
      [field]: event.target.checked,
      ...(field === 'chantier_depart' && mission.chantier_depart && mission.voie_depart && { voie_depart: false }),
      ...(field === 'voie_depart' && !mission.chantier_depart && !mission.voie_depart && { chantier_depart: true }),
      ...(field === 'chantier_arrivee' && mission.chantier_arrivee && mission.voie_arrivee && { voie_arrivee: false }),
      ...(field === 'voie_arrivee' && !mission.chantier_arrivee && !mission.voie_arrivee && { chantier_arrivee: true }),
    },
  )

  if (missionsLoading) {
    return (
      <div className="missions-form">
        <Loader message={localTerms.loading} standalone />
      </div>
    )
  }
  return (
    <div className="missions-form">
      {missions.map(mission => (
        <div className="item" key={mission.id}>
          <div className="text-fields">
            <div className="input">
              <span>{localTerms.label}</span>
              <AsyncInput
                value={mission.libelle}
                handleChangeCallback={handleChangeMissionName(mission.id)}
              />
            </div>
            <div className="input">
              <span>{localTerms.duration}</span>
              <AsyncInput
                value={mission.duree_theorique.toFixed()}
                handleChangeCallback={handleChangeDuration(mission.id)}
                type="number"
              />
            </div>
            <TooltipButton
              icon={<Delete />}
              tooltipText={localTerms.delete}
              onClick={handleDeleteMission(mission.id)}
            />
          </div>
          <MissionBooleanFields
            id={mission.id}
            mission={mission}
            handleClickField={handleChangeBooleanField(mission)}
          />
        </div>
      ))}
      {addingMission ? (
        <AddMissionForm
          handleAddMission={handlePostNewMission(() => setAddingMission(false))}
          handleCancel={() => setAddingMission(false)}
        />
      ) : (
        <Button
          className="add-mission-button"
          onClick={() => setAddingMission(true)}
          text={localTerms.add}
          startIcon={<Add />}
        />
      )}
    </div>
  )
}
