import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ManageState, ManageTeam } from 'types'
import { getCOMs, getConsultants, getTeams } from './thunks'

const initialState: ManageState = {
  coms: [],
  comsLoading: false,
  consultants: [],
  consultantsLoading: false,
  teams: [],
  teamsLoading: false,
}

export const manageSlice = createSlice({
  name: 'manage',
  initialState,
  reducers: {
    clearManageState: () => initialState,
    postNewCOM: (state, action: PayloadAction<string>) => {
      state.coms = [
        ...state.coms.filter(com => com.cerbere_id !== action.payload),
        { cerbere_id: action.payload },
      ]
    },
    deleteCOM: (state, action: PayloadAction<string>) => {
      state.coms = state.coms.filter(com => com.cerbere_id !== action.payload)
    },
    postNewConsultant: (state, action: PayloadAction<string>) => {
      state.consultants = [
        ...state.consultants.filter(consultant => consultant.cerbere_id !== action.payload),
        { cerbere_id: action.payload },
      ]
    },
    deleteConsultant: (state, action: PayloadAction<string>) => {
      state.consultants = state.consultants.filter(consultant => consultant.cerbere_id !== action.payload)
    },
    postNewTeam: (state, action: PayloadAction<ManageTeam>) => {
      state.teams = [
        ...state.teams.filter(team => team.id !== action.payload.id),
        { agents: [], ...action.payload },
      ]
    },
    deleteTeam: (state, action: PayloadAction<string>) => {
      state.teams = state.teams.filter(team => team.id !== action.payload)
    },
    addTeamMember: (state, action: PayloadAction<{ teamId: string; agentId: string }>) => {
      state.teams = state.teams.map(team => {
        if (team.id === action.payload.teamId) {
          return { ...team, agents: [...team.agents, action.payload.agentId] }
        }
        return team
      })
    },
    removeTeamMember: (state, action: PayloadAction<{ teamId: string; agentId: string }>) => {
      state.teams = state.teams.map(team => {
        if (team.id === action.payload.teamId) {
          return { ...team, agents: team.agents.filter(agent => agent !== action.payload.agentId) }
        }
        return team
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(getCOMs.pending, state => {
      state.comsLoading = true
    })
    builder.addCase(getCOMs.fulfilled, (state, action) => {
      state.coms = action.payload
      state.comsLoading = false
    })
    builder.addCase(getCOMs.rejected, state => {
      state.comsLoading = false
    })
    builder.addCase(getConsultants.pending, state => {
      state.consultantsLoading = true
    })
    builder.addCase(getConsultants.fulfilled, (state, action) => {
      state.consultants = action.payload
      state.consultantsLoading = false
    })
    builder.addCase(getConsultants.rejected, state => {
      state.consultantsLoading = false
    })
    builder.addCase(getTeams.pending, state => {
      state.teamsLoading = true
    })
    builder.addCase(getTeams.fulfilled, (state, action) => {
      state.teams = action.payload
      state.teamsLoading = false
    })
    builder.addCase(getTeams.rejected, state => {
      state.teamsLoading = false
    })
  },
})

export const {
  clearManageState,
  postNewCOM,
  deleteCOM,
  postNewConsultant,
  deleteConsultant,
  postNewTeam,
  deleteTeam,
  addTeamMember,
  removeTeamMember,
} = manageSlice.actions

export default manageSlice.reducer
