import { signal } from '@preact/signals-react'
import { MissionNotification } from 'types'
import './NotificationsWrapper.scss'
import { ReactNode } from 'react'
import { Add, Check, Close, Edit, Inventory2Outlined } from '@mui/icons-material'
import moment from 'moment'

export const notificationsSignal = signal<MissionNotification[]>([])

const iconColor: { [key in MissionNotification['type']]: string } = {
  AFFECTATION: '#43CF7B',
  MODIFICATION: '#FAB142',
  REFUS: '#DA4238',
  CREATION: '#FAB142',
  SUPPRESSION: '#DA4238',
  VALIDATION: '#43CF7B',
  ARCHIVAGE: '##B7B9BF',
}

const IconComponent: { [key in MissionNotification['type']]: ReactNode } = {
  AFFECTATION: <Check />,
  MODIFICATION: <Edit />,
  REFUS: <Close />,
  CREATION: <Add />,
  SUPPRESSION: <Close />,
  VALIDATION: <Check />,
  ARCHIVAGE: <Inventory2Outlined />,
}

const message: { [key in MissionNotification['type']]: string } = {
  AFFECTATION: 'Affectée',
  MODIFICATION: 'Modifiée',
  REFUS: 'Refusée',
  CREATION: 'Ajoutée',
  SUPPRESSION: 'Supprimée',
  VALIDATION: 'Validée',
  ARCHIVAGE: 'Archivée',
}

const closeNotification = (id: string) => () => {
  notificationsSignal.value = notificationsSignal.value.filter(notif => notif.id !== id)
}

export default function NotificationsWrapper() {
  if (notificationsSignal.value.length === 0) return null
  return (
    <div className="notifications-wrapper">
      {notificationsSignal.value.slice(-5).map(notification => (
        <div className="notif" key={notification.id}>
          <div
            className="icon flex-center"
            style={{
              color: iconColor[notification.type],
              backgroundColor: `${iconColor[notification.type]}33`,
            }}
          >
            {IconComponent[notification.type]}
          </div>
          <div className="content">
            <span className="title">
              {`Mission ${notification.numero} : ${message[notification.type]} par le ${notification.origine}`}
            </span>
            <span>{moment(notification.timestamp).format('llll')}</span>
          </div>
          <button type="button" onClick={closeNotification(notification.id)}>
            <Close />
          </button>
        </div>
      ))}
    </div>
  )
}
