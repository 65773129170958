import { ReactElement } from 'react'
import { Tooltip, TooltipProps } from '@mui/material'
import { QuestionMark } from '@mui/icons-material'
import './TooltipButton.scss'

export enum TooltipButtonStyle {
  borderLess = 'borderLess',
}

interface Props {
  style?: TooltipButtonStyle;
  icon?: ReactElement;
  tooltipText: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  placement?: TooltipProps['placement'];
}

export default function TooltipButton({
  style = TooltipButtonStyle.borderLess,
  icon = <QuestionMark />,
  tooltipText,
  onClick,
  disabled = false,
  className = '',
  placement = 'top',
}: Props): ReactElement {
  return (
    <Tooltip title={tooltipText} placement={placement}>
      <button
        type="button"
        className={`tooltip-button button--${style} ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </button>
    </Tooltip>
  )
}
