import { ArrowBackIos } from '@mui/icons-material'
import { Box, Link } from '@mui/material'

interface Props {
  onClick: () => void
}

export default function BackButton({ onClick }: Props) {
  return (
    <Link
      component="button"
      underline="none"
      sx={{
        padding: '24px',
        '&:hover, &:focus': {
          backgroundColor: '#E6F0FF',
          borderRadius: '4px',
        },
      }}
      onClick={onClick}
    >
      <Box display="flex" alignItems="center">
        <ArrowBackIos />
        {' '}
        Retour à la liste des missions
      </Box>
    </Link>
  )
}
