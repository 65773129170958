import { Checkbox } from 'components'
import './CheckboxFormField.scss'

interface Props {
  value: boolean;
  onChange: () => void;
  label?: string;
  name: string;
}

export default function CheckboxFormField({
  value,
  onChange,
  label,
  name,
}: Props) {
  return (
    <div className="checkbox-form-field">
      {label && (
        <span>
          {label}
        </span>
      )}
      <Checkbox
        checked={value}
        onChange={onChange}
        label={name}
      />
    </div>
  )
}
