import { createSlice } from '@reduxjs/toolkit'
import { RdState } from 'types/rd'
import { getAffectedMissionsRD, getMissionRD, getInProgressMissionsRD } from './thunks'

const initialState: RdState = {
  missionsRD: [],
  inProgressMissionsRD: [],
  missionRD: null,
  loadingMissionRD: false,
}

export const rdSlice = createSlice({
  name: 'rd',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAffectedMissionsRD.fulfilled, (state, action) => {
      state.missionsRD = action.payload
    })
    builder.addCase(getInProgressMissionsRD.fulfilled, (state, action) => {
      state.inProgressMissionsRD = action.payload
    })
    builder.addCase(getMissionRD.fulfilled, (state, action) => {
      state.missionRD = action.payload
      state.loadingMissionRD = false
    })
    builder.addCase(getMissionRD.pending, state => {
      state.loadingMissionRD = true
      state.missionRD = null
    })
    builder.addCase(getMissionRD.rejected, state => {
      state.loadingMissionRD = false
    })
  },
})

export default rdSlice.reducer
