import Loader from 'components/loaderComponent/Loader'
import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import './AsyncInput.scss'
import { ErrorOutline } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import terms from 'assets/terms'
import { parseErrorMessage } from 'utils'

interface Props<T> {
  value: string;
  handleChangeCallback: (value: string) => Promise<T>;
  type?: string;
}

export default function AsyncInput<T>({ value, handleChangeCallback, type = 'text' }: Props<T>) {
  const [inputValue, setInputValue] = useState(value)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState<string>(terms.Error.httpStatus.badRequest)

  const debouncedCallback = useCallback(debounce((newValue: string) => {
    setLoading(true)
    handleChangeCallback(newValue)
      .catch(e => {
        setError(true)
        setErrorText(parseErrorMessage(e, terms.Error.httpStatus.badRequest))
      })
      .then(() => setLoading(false))
  }, 1000), [])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setInputValue(newValue)
    setError(false)
    debouncedCallback(newValue)
  }

  useEffect(() => { setInputValue(value) }, [value])

  return (
    <div className={`async-input${error ? ' error' : ''}`}>
      <input
        type={type}
        onChange={handleChange}
        value={inputValue}
      />
      {loading && <div className="loading"><Loader variant="small" standalone /></div>}
      {error && (
      <div className="error-icon">
        <Tooltip title={errorText}>
          <ErrorOutline />
        </Tooltip>
      </div>
      )}
    </div>
  )
}
