import { get } from '@osrdata/app_core/dist/requests'
import { ManageCom, ManageConsultant, ManageTeam } from 'types'
import { createApiThunk } from 'utils'

const getCOMs = createApiThunk(
  'r2d2/getCOMs',
  (technicenterId: string) => get<ManageCom[]>(`/r2d2/technicentres/${technicenterId}/coms/`),
)

const getConsultants = createApiThunk(
  'r2d2/getConsultants',
  (technicenterId: string) => get<ManageConsultant[]>(`/r2d2/technicentres/${technicenterId}/consultants/`),
)

const getTeams = createApiThunk(
  'r2d2/getTeams',
  (technicenterId: string) => get<ManageTeam[]>(`/r2d2/technicentres/${technicenterId}/equipe/`),
)

export {
  getCOMs,
  getConsultants,
  getTeams,
}
