import terms from 'assets/terms'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Fragment } from 'react/jsx-runtime'
import { ADMIN_PERM, useAppDispatch, useAppSelector } from 'utils'
import { setActiveRole } from 'reducers/app'
import { routes } from 'services'
import { AppRoles } from 'types'
import ButtonSelect from './ButtonSelect'
import './HomePage.scss'

export default function HomePage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userRoles, loadingUserRoles } = useAppSelector(state => state.app)
  const { appPermissions } = useAppSelector(state => state.user)

  const handleClickAdmin = () => {
    navigate(routes.params)
    dispatch(setActiveRole({
      role: AppRoles.admin,
    }))
  }

  const handleClickRole = (role: AppRoles, technicenterId: string, technicenterName :string) => () => {
    switch (role) {
      case AppRoles.manager:
        navigate(routes.manage)
        dispatch(setActiveRole({
          role: AppRoles.manager,
          technicenterId,
          technicenterName,
        }))
        break
      case AppRoles.consultant:
      case AppRoles.com:
        navigate(routes.missionTable)
        dispatch(setActiveRole({
          role,
          technicenterId,
          technicenterName,
        }))
        break
      case AppRoles.RD:
        navigate(routes.rd)
        dispatch(setActiveRole({
          role: AppRoles.RD,
          technicenterId,
          technicenterName,
        }))
        break
      default:
        break
    }
  }

  const handleUniqueRole = () => {
    const adminRole = appPermissions.includes(ADMIN_PERM) ? 1 : 0
    const roleCount = userRoles.flatMap(r => r.roles).length
    if ((roleCount + adminRole) === 1) {
      if (adminRole) {
        handleClickAdmin()
      } else {
        const role = userRoles[0].roles[0]
        handleClickRole(role, userRoles[0].id, userRoles[0].libelle)()
      }
    }
  }

  useEffect(() => {
    dispatch(setActiveRole(null))
    handleUniqueRole()
  }, [])

  return (
    <div
      id="homePage"
      className="page"
    >
      <h1 className="page-title">{terms.Pages.Home.title}</h1>

      <div className="role-selection">
        {!loadingUserRoles && userRoles.length === 0 && !appPermissions.includes(ADMIN_PERM) && (
        <span>{terms.Pages.Home.noRole}</span>
        )}
        {!loadingUserRoles && (userRoles.length > 0 || appPermissions.includes(ADMIN_PERM)) && (
        <span>{terms.Pages.Home.chooseRole}</span>
        )}
        <div className="roles">
          {appPermissions.includes(ADMIN_PERM) && (
            <ButtonSelect
              text={terms.Common.roles[AppRoles.admin]}
              onClick={handleClickAdmin}
            />
          )}
          {!loadingUserRoles && userRoles.map(technicenter => (
            <Fragment key={technicenter.id}>
              <span>{technicenter.libelle}</span>
              {technicenter.roles.map(role => (
                <ButtonSelect
                  key={role}
                  text={terms.Common.roles[role]}
                  onClick={handleClickRole(role, technicenter.id, technicenter.libelle)}
                />
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
