import terms from 'assets/terms'
import { Button, ButtonStyle } from 'components'
import { ParamsTab } from 'types'

interface Props {
  tabs: ParamsTab[];
  activeTab: ParamsTab;
  handleClickTab: (tab: ParamsTab) => () => void;
}

export default function ParamsSideMenu({
  tabs, activeTab, handleClickTab,
}: Props) {
  return (
    <div className="params-side-menu">
      <h2>{terms.Pages.Params.title}</h2>
      <div className="tabs">
        {tabs.map(tab => (
          <Button
            key={tab.id}
            text={tab.title}
            style={activeTab.id === tab.id ? ButtonStyle.menuSelected : ButtonStyle.menuBorderLess}
            onClick={handleClickTab(tab)}
          />
        ))}
      </div>
    </div>
  )
}
