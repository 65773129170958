import { Button, ButtonStyle } from 'components'
import { useNavigate } from 'react-router-dom'
import { getMissionRD } from 'reducers/rd/thunks'
import {
  handleEndTestsRD, handlePresenceOnEngineRD,
  handleRefuseMissionRD, handleStartMissionRD, handleStartTestsRD, handleValidateMissionRD, routes,
} from 'services'
import { MissionRDProgress } from 'types'
import { useAppDispatch } from 'utils'

interface Props {
  missionId: string
  technicalCenterId: string
  progress: MissionRDProgress
}

export default function MissionActions({ missionId, technicalCenterId, progress }: Props) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleStart = handleStartMissionRD(
    technicalCenterId,
    missionId,
    () => {
      navigate(`${routes.rd}${routes.rdMissionsList}/inprogress/${missionId}`)
      dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: missionId }))
    },
  )

  const handleEnginePresence = handlePresenceOnEngineRD(
    technicalCenterId,
    missionId,
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: missionId })) },
  )

  const handleStartTests = handleStartTestsRD(
    technicalCenterId,
    missionId,
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: missionId })) },
  )

  const handleEndTests = handleEndTestsRD(
    technicalCenterId,
    missionId,
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: missionId })) },
  )

  const handleValidate = handleValidateMissionRD(
    technicalCenterId,
    missionId,
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: missionId })) },
  )

  const handleCancel = handleRefuseMissionRD(
    technicalCenterId,
    missionId,
    () => {
      navigate(routes.rd)
      dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: missionId }))
    },
  )

  return (
    <div className="rd-mission-actions">
      {progress === MissionRDProgress.notStarted && (
        <Button
          fullWidth
          text="Prendre la mission"
          onClick={handleStart}
        />
      )}
      {progress === MissionRDProgress.started && (
        <>
          <Button
            fullWidth
            text="Présence sur l'engin"
            onClick={handleEnginePresence}
          />
          <span>
            Signalez votre présence sur l’engin pour pouvoir noter des défauts constatés
          </span>
        </>
      )}
      {(progress === MissionRDProgress.started || progress === MissionRDProgress.notStarted) && (
        <Button
          fullWidth
          text="Refuser la mission"
          onClick={handleCancel}
          style={ButtonStyle.danger}
        />
      )}
      {progress === MissionRDProgress.atEngine && (
        <Button
          fullWidth
          text="Prêt au départ"
          onClick={handleStartTests}
        />
      )}
      {progress === MissionRDProgress.testsStarted && (
        <Button
          fullWidth
          text="Essais et missions terminés"
          onClick={handleEndTests}
          style={ButtonStyle.success}
        />
      )}
      {progress === MissionRDProgress.testsEnded && (
        <Button
          fullWidth
          text="Valider la mission"
          onClick={handleValidate}
          style={ButtonStyle.success}
        />
      )}
    </div>
  )
}
