import { AppRoles, MissionStatus } from 'types'

/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    save: 'Enregistrer',
    validate: 'Valider',
    noResults: 'Aucun résultat',
    continue: 'Continuer',
    roles: {
      [AppRoles.manager]: 'Gestionnaire',
      [AppRoles.com]: 'COM',
      [AppRoles.consultant]: 'Consultant',
      [AppRoles.admin]: 'Administrateur',
      [AppRoles.RD]: 'RD',
    },
    missionStatus: {
      [MissionStatus.preAssigned]: 'Pré-affectée',
      [MissionStatus.assigned]: 'Affectée',
      [MissionStatus.inProgress]: 'Mission en cours',
      [MissionStatus.validated]: 'Validée',
      [MissionStatus.canceled]: 'Refusée',
      [MissionStatus.deleted]: 'Supprimée',
    },
    unknownUser: 'Utilisateur inconnu',
  },
  AccountMenu: {
    logout: 'Déconnexion',
    roleSelection: 'Choisir un autre rôle',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      serverDown: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
    params: {
      trackPostError: 'Erreur lors de la création de la voie.',
      trackDeleteError: 'Erreur lors de la suppression de la voie.',
      constructionPostError: 'Erreur lors de la création du chantier.',
      constructionDeleteError: 'Erreur lors de la suppression du chantier.',
      directionDeleteError: 'Erreur lors de la suppression du sens de départ.',
      directionPostError: 'Erreur lors de la création du sens de départ.',
      observationPostError: 'Erreur lors de la création de l\'observation.',
      observationDeleteError: 'Erreur lors de la suppression de l\'observation.',
      defectPostError: 'Erreur lors de la création du défaut.',
      defectDeleteError: 'Erreur lors de la suppression du défaut.',
      additionalMissionPostError: 'Erreur lors de la création de la mission supplémentaire.',
      additionalMissionDeleteError: 'Erreur lors de la suppression de la mission supplémentaire.',
      missionPostError: 'Erreur lors de la création de la mission.',
      missionDeleteError: 'Erreur lors de la suppression de la mission.',
    },
  },
  SideMenu: {
    links: {
      myViz: 'Mes visualisations',
      help: 'Demande d’assistance',
    },
  },
  Tabs: {
    home: 'Accueil',
    params: 'Paramètrage',
    manage: 'Gestion',
  },
  Pages: {
    Home: {
      title: 'Bienvenue sur l\'application R2D2',
      chooseRole: 'Choisissez un rôle parmi ceux qui vous sont attribués :',
      noRole: 'Vous n\'avez pas de rôle attribué dans l\'application. Veuillez contacter le gestionnaire de votre technicentre pour obtenir un accès.',
      admin: 'Administrateur',
    },
    Params: {
      title: 'Paramétrage',
      tabs: {
        missions: 'Missions',
        additionalMissions: 'Missions supplémentaires',
        technicalCenters: 'Technicentres',
        rdObservations: 'Observations RD',
        rdDefects: 'Défauts RD',
      },
      description: {
        missions: 'Gérez les différentes missions, leurs durées théoriques ainsi que leurs attributs. Les attributs cochés seront des champs rendus obligatoires.',
        additionalMissions: 'Gérez les différentes missions supplémentaires, et leurs durées théoriques.',
        technicalCenters: 'Gérez les différents chantiers et les voies qui lui sont affectées.',
        rdObservations: 'Gérez les observations RD.',
        rdDefects: 'Gérez les défauts RD.',
      },
      missions: {
        loading: 'Chargement des missions',
        title: 'Missions',
        label: 'Libellé',
        duration: 'Durée théorique (minutes)',
        mandatoryFields: 'Champs obligatoire(s) :',
        constructionStart: 'Chantier de départ',
        trackStart: 'Voie de départ',
        constructionEnd: 'Chantier d\'arrivée',
        trackEnd: 'Voie d\'arrivée',
        direction: 'Sens de départ',
        via: 'Via',
        add: 'Ajouter une mission',
        cancel: 'Annuler l\'ajout de mission',
        delete: 'Supprimer la mission',
      },
      additionalMissions: {
        loading: 'Chargement des missions supplémentaires',
        title: 'Missions supplémentaires',
        label: 'Libellé',
        duration: 'Durée théorique (minutes)',
        add: 'Ajouter une mission supplémentaire',
        delete: 'Supprimer la mission supplémentaire',
        cancel: 'Annuler l\'ajout de mission supplémentaire',
      },
      technicalCenters: {
        loading: 'Chargement des technicentres',
        technicalCenterTitle: 'Technicentre n°',
        constructionTitle: 'Chantier n°',
        addConstruction: 'Ajouter un chantier',
        deleteConstruction: 'Supprimer le chantier',
        newConstruction: 'Nouveau chantier',
        cancelConstruction: 'Annuler l\'ajout de chantier',
        trackTitle: 'Voie n°',
        addTrack: 'Ajouter une voie',
        deleteTrack: 'Supprimer la voie',
        newTrack: 'Nouvelle voie',
        cancelTrack: 'Annuler l\'ajout de voie',
        constructions: 'Chantiers',
        direction: 'Sens de départ',
        directionTitle: 'Sens de départ n°',
        deleteDirection: 'Supprimer le sens de départ',
        newDirection: 'Nouveau sens de départ',
        addDirection: 'Ajouter un sens de départ',
        cancelDirection: 'Annuler l\'ajout de sens de départ',
      },
      observations: {
        loading: 'Chargement des observations RD',
        title: 'Observations RD',
        label: 'Libellé',
        add: 'Ajouter une observation',
        delete: 'Supprimer l\'observation RD',
        cancel: 'Annuler l\'ajout d\'observation',
      },
      defects: {
        loading: 'Chargement des défauts RD',
        title: 'Défauts RD',
        label: 'Libellé',
        add: 'Ajouter un défaut',
        delete: 'Supprimer le défaut RD',
        cancel: 'Annuler l\'ajout de défaut',
      },
    },
    Manage: {
      tabs: {
        teams: 'Équipes',
        com: 'COM',
        consultants: 'Consultants',
      },
      description: {
        teams: 'Ajoutez, modifiez et supprimez des équipes.',
        com: 'Habilitez les COM sur votre technicentre',
        consultants: 'Habilitez les consultants sur votre technicentre.',
      },
      title: 'Gestion',
      coms: {
        loading: 'Chargement des COM',
        add: 'Ajouter un COM',
        confirmDelete: 'Voulez-vous vraiment supprimer le COM ?',
        delete: 'Supprimer le COM',
        searchPlaceholder: 'Rechercher un COM',
        searchLabel: 'Nom du membre',
      },
      consultants: {
        loading: 'Chargement des consultants',
        add: 'Ajouter un consultant',
        confirmDelete: 'Voulez-vous vraiment supprimer le consultant ?',
        delete: 'Supprimer le consultant',
        searchPlaceholder: 'Rechercher un consultant',
        searchLabel: 'Nom du membre',
      },
      teams: {
        loading: 'Chargement des équipes',
        add: 'Ajouter une équipe',
        teamName: 'Nom de l\'équipe',
        confirmDelete: 'Voulez-vous vraiment supprimer l\'équipe ?',
        delete: 'Supprimer l\'équipe',
        searchLabel: 'Nom de l\'équipe',
        addMember: 'Ajouter un membre',
        memberLabel: 'Nom du membre',
        removeMember: 'Supprimer le membre de l\'équipe',
        confirmDeleteMember: 'Voulez-vous vraiment supprimer le membre de l\'équipe ?',
      },
    },
  },
  Modals: {
    MissionManageTeam: {
      title: 'Equipe du service en cours',
      selectTeam: 'Sélectionnez l’équipe de RD qui correspond au service en cours :',
      selectedPeople: 'personne(s) sélectionnée(s)',
      noPeopleSelected: 'Aucune personne sélectionnée',
      noTeam: 'Sélectionnez une équipe pour voir les agents',
    },
    AddMissionTC: {
      rame: 'Rame',
      um: 'UM',
      umDetails: 'Matériel attelé',
      mission: 'Mission',
      rdHidden: 'Non visible pour le RD',
      urgent: 'Prioritaire',
      chantier_depart: 'Chantier de départ',
      voie_depart: 'Voie de départ',
      sens_depart: 'Sens de départ',
      chantier_arrivee: 'Chantier d\'arrivée',
      voie_arrivee: 'Voie d\'arrivée',
      via: 'Via',
      heure_debut_theorique: 'Heure de début théorique',
      heure_fin_theorique: 'Heure de fin théorique',
      agent: 'Agent (si affectation)',
      missions_supplementaires: 'Missions supplémentaires',
      observation_com: 'Observation COM',
      pancartage: 'Pancartage',
      assignment: 'Affectation',
      preassignment: 'Pré-affectation',
    },
  },
  Messages: {
    success: {
      manage: {
        postCom: 'Le COM a bien été ajouté.',
        deleteCom: 'Le COM a bien été supprimé.',
        postConsultant: 'Le consultant a bien été ajouté.',
        deleteConsultant: 'Le consultant a bien été supprimé.',
        postTeam: 'L\'équipe a bien été ajoutée.',
        deleteTeam: 'L\'équipe a bien été supprimée.',
        postTeamMember: 'Le membre a bien été ajouté à l\'équipe.',
        deleteTeamMember: 'Le membre a bien été supprimé de l\'équipe.',
        changeTeamMember: 'Le membre a bien été changé d\'équipe.',
      },
      missionTable: {
        updateAgents: 'Les agents ont bien été mis à jour.',
      },
    },
    errors: {
      manage: {
        postCom: 'Le COM n\'a pas pu être ajouté. Veuillez réessayer.',
        deleteCom: 'Le COM n\'a pas pu être supprimé. Veuillez réessayer.',
        postConsultant: 'Le consultant n\'a pas pu être ajouté. Veuillez réessayer.',
        deleteConsultant: 'Le consultant n\'a pas pu être supprimé. Veuillez réessayer.',
        postTeam: 'L\'équipe n\'a pas pu être ajoutée. Veuillez réessayer.',
        deleteTeam: 'L\'équipe n\'a pas pu être supprimée. Veuillez réessayer.',
        postTeamMember: 'Le membre n\'a pas pu être ajouté à l\'équipe. Veuillez réessayer.',
        deleteTeamMember: 'Le membre n\'a pas pu être supprimé de l\'équipe. Veuillez réessayer.',
        changeTeamMember: 'Le membre n\'a pas pu être changé d\'équipe. Veuillez réessayer.',
      },
      missionTable: {
        updateAgents: 'Erreur lors de la mise à jour des agents. Veuillez réessayer.',
        deleteTCMission: 'Erreur lors de la suppression de la mission. Veuillez réessayer.',
        patchTCMission: 'Erreur lors de la modification de la mission. Veuillez réessayer.',
        startTCMission: 'Erreur lors du démarrage de la mission. Veuillez réessayer.',
        missionTCPE: 'Erreur lors de la prise en compte de la présence sur engin. Veuillez réessayer.',
        missionTCNPD: 'Erreur lors du début du "Ne pas déranger". Veuillez réessayer.',
        missionTCValidate: 'Erreur lors de la validation de la mission. Veuillez réessayer.',
        missionBatchAction: 'Erreur lors de l\'action sur les missions. Veuillez réessayer.',
        patchAdditionnalMissionsTC: 'Erreur lors de la modification des missions supplémentaires. Veuillez réessayer.',
      },
      rd: {
        acceptMission: 'Erreur lors de la prise en charge de la mission. Veuillez réessayer.',
        refuseMission: 'Erreur lors du refus de la mission. Veuillez réessayer.',
        presenceOnEngine: 'Erreur lors de l\'envoi de la présence sur l\'engin. Veuillez réessayer.',
        startTests: 'Erreur lors de l\'envoi du début des tests. Veuillez réessayer.',
        endTests: 'Erreur lors de l\'envoi de la fin des tests. Veuillez réessayer.',
        validateMission: 'Erreur lors de la validation de la mission. Veuillez réessayer.',
        patchMission: 'Erreur lors de la modification de la mission. Veuillez réessayer.',
      },
    },
  },
} as const

export default terms
