import { Check } from '@mui/icons-material'
import moment from 'moment'
import { MissionRD } from 'types'

interface Props {
  mission: MissionRD
}

export default function MissionProgress({ mission }: Props) {
  return (
    <>
      {mission?.prise_en_compte && (
        <div className="mission-progress">
          <Check color="success" />
          <span>Mission prise en compte</span>
          <span>
            {moment(mission.prise_en_compte).format('HH:mm')}
          </span>
        </div>
      )}
      {mission?.presence_sur_engin && (
        <div className="mission-progress">
          <Check color="success" />
          <span>Présence sur l’engin</span>
          <span>
            {moment(mission.presence_sur_engin).format('HH:mm')}
          </span>
        </div>
      )}
      {mission?.fin_npd && (
        <div className="mission-progress">
          <Check color="success" />
          <span>Essais et missions terminés</span>
          <span>
            {moment(mission.fin_npd).format('HH:mm')}
          </span>
        </div>
      )}
    </>
  )
}
