import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ParamsAdditionalMission, ParamsConstruction, ParamsMission, ParamsState, ParamsTrack } from 'types'
import { getAdditionalMissions, getDefects, getMissions, getObservations, getTechnicalCenters } from './thunks'

const initialState: ParamsState = {
  missions: [],
  missionsLoading: false,
  additionalMissions: [],
  additionalMissionsLoading: false,
  technicalCenters: [],
  technicalCentersLoading: false,
  observations: [],
  observationsLoading: false,
  defects: [],
  defectsLoading: false,
}

export const paramsSlice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    postNewTrack: (state, action: PayloadAction<{centerId: string, constructionId: string, track: ParamsTrack}>) => {
      const { centerId, constructionId, track } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return {
            ...tc,
            chantiers: tc.chantiers.map(construction => {
              if (construction.id === constructionId) {
                return { ...construction, voies: [...construction.voies, track] }
              }
              return construction
            }),
          }
        }
        return tc
      })
    },
    deleteTrack: (state, action: PayloadAction<{centerId: string, constructionId: string, trackId: string}>) => {
      const { centerId, constructionId, trackId } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return {
            ...tc,
            chantiers: tc.chantiers.map(construction => {
              if (construction.id === constructionId) {
                return {
                  ...construction,
                  voies: construction.voies.filter(track => track.id !== trackId),
                }
              }
              return construction
            }),
          }
        }
        return tc
      })
    },
    postNewConstruction: (state, action: PayloadAction<{centerId: string, construction: ParamsConstruction}>) => {
      const { centerId, construction } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return { ...tc, chantiers: [...tc.chantiers, construction] }
        }
        return tc
      })
    },
    deleteConstruction: (state, action: PayloadAction<{centerId: string, constructionId: string}>) => {
      const { centerId, constructionId } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return { ...tc, chantiers: tc.chantiers.filter(construction => construction.id !== constructionId) }
        }
        return tc
      })
    },
    patchConstructionName: (state, action: PayloadAction<{
      centerId: string, constructionId: string, libelle: string
    }>) => {
      const { centerId, constructionId, libelle } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return {
            ...tc,
            chantiers: tc.chantiers.map(construction => {
              if (construction.id === constructionId) {
                return { ...construction, libelle }
              }
              return construction
            }),
          }
        }
        return tc
      })
    },
    patchTrackName: (state, action: PayloadAction<{
      centerId: string, constructionId: string, trackId: string, libelle: string
    }>) => {
      const { centerId, constructionId, trackId, libelle } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return {
            ...tc,
            chantiers: tc.chantiers.map(construction => {
              if (construction.id === constructionId) {
                return {
                  ...construction,
                  voies: construction.voies.map(track => {
                    if (track.id === trackId) {
                      return { ...track, libelle }
                    }
                    return track
                  }),
                }
              }
              return construction
            }),
          }
        }
        return tc
      })
    },
    patchDirectionName: (state, action: PayloadAction<{centerId: string, directionId: string, libelle: string}>) => {
      const { centerId, directionId, libelle } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return {
            ...tc,
            sens_depart: tc.sens_depart.map(direction => {
              if (direction.id === directionId) {
                return { ...direction, libelle }
              }
              return direction
            }),
          }
        }
        return tc
      })
    },
    deleteDirection: (state, action: PayloadAction<{centerId: string, directionId: string}>) => {
      const { centerId, directionId } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return { ...tc, sens_depart: tc.sens_depart.filter(direction => direction.id !== directionId) }
        }
        return tc
      })
    },
    postNewDirection: (state, action: PayloadAction<{centerId: string, direction: {id: string, libelle: string}}>) => {
      const { centerId, direction } = action.payload
      state.technicalCenters = state.technicalCenters.map(tc => {
        if (tc.id === centerId) {
          return { ...tc, sens_depart: [...tc.sens_depart, direction] }
        }
        return tc
      })
    },
    postNewObservation: (state, action: PayloadAction<{id: string, libelle: string}>) => {
      state.observations = [...state.observations, action.payload]
    },
    patchObservationName: (state, action: PayloadAction<{id: string, libelle: string}>) => {
      const { id, libelle } = action.payload
      state.observations = state.observations.map(observation => {
        if (observation.id === id) {
          return { ...observation, libelle }
        }
        return observation
      })
    },
    deleteObservation: (state, action: PayloadAction<string>) => {
      state.observations = state.observations.filter(observation => observation.id !== action.payload)
    },
    postNewDefect: (state, action: PayloadAction<{id: string, libelle: string}>) => {
      state.defects = [...state.defects, action.payload]
    },
    patchDefectName: (state, action: PayloadAction<{id: string, libelle: string}>) => {
      const { id, libelle } = action.payload
      state.defects = state.defects.map(defect => {
        if (defect.id === id) {
          return { ...defect, libelle }
        }
        return defect
      })
    },
    deleteDefect: (state, action: PayloadAction<string>) => {
      state.defects = state.defects.filter(defect => defect.id !== action.payload)
    },
    postNewAdditionalMission: (state, action: PayloadAction<ParamsAdditionalMission>) => {
      state.additionalMissions = [...state.additionalMissions, action.payload]
    },
    deleteAdditionalMission: (state, action: PayloadAction<string>) => {
      state.additionalMissions = state.additionalMissions.filter(mission => mission.id !== action.payload)
    },
    patchAdditionnalMissionName: (state, action: PayloadAction<{id: string, libelle: string}>) => {
      const { id, libelle } = action.payload
      state.additionalMissions = state.additionalMissions.map(mission => {
        if (mission.id === id) {
          return { ...mission, libelle }
        }
        return mission
      })
    },
    patchAdditionalMissionDuration: (state, action: PayloadAction<{id: string, duree_theorique: number}>) => {
      const { id, duree_theorique: duration } = action.payload
      state.additionalMissions = state.additionalMissions.map(mission => {
        if (mission.id === id) {
          return { ...mission, duration }
        }
        return mission
      })
    },
    postNewMission: (state, action: PayloadAction<ParamsMission>) => {
      state.missions = [...state.missions, action.payload]
    },
    deleteMission: (state, action: PayloadAction<string>) => {
      state.missions = state.missions.filter(mission => mission.id !== action.payload)
    },
    patchMission: (state, action: PayloadAction<{id: string, mission: Partial<ParamsMission>}>) => {
      const { id, mission } = action.payload
      state.missions = state.missions.map(m => {
        if (m.id === id) {
          return { ...m, ...mission }
        }
        return m
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(getMissions.pending, state => {
      state.missionsLoading = true
    })
    builder.addCase(getMissions.fulfilled, (state, action) => {
      state.missions = action.payload
      state.missionsLoading = false
    })
    builder.addCase(getMissions.rejected, state => {
      state.missionsLoading = false
    })
    builder.addCase(getAdditionalMissions.pending, state => {
      state.additionalMissionsLoading = true
    })
    builder.addCase(getAdditionalMissions.fulfilled, (state, action) => {
      state.additionalMissions = action.payload
      state.additionalMissionsLoading = false
    })
    builder.addCase(getAdditionalMissions.rejected, state => {
      state.additionalMissionsLoading = false
    })
    builder.addCase(getTechnicalCenters.pending, state => {
      state.technicalCentersLoading = true
    })
    builder.addCase(getTechnicalCenters.fulfilled, (state, action) => {
      state.technicalCenters = action.payload
      state.technicalCentersLoading = false
    })
    builder.addCase(getTechnicalCenters.rejected, state => {
      state.technicalCentersLoading = false
    })
    builder.addCase(getObservations.pending, state => {
      state.observationsLoading = true
    })
    builder.addCase(getObservations.fulfilled, (state, action) => {
      state.observations = action.payload
      state.observationsLoading = false
    })
    builder.addCase(getObservations.rejected, state => {
      state.observationsLoading = false
    })
    builder.addCase(getDefects.pending, state => {
      state.defectsLoading = true
    })
    builder.addCase(getDefects.fulfilled, (state, action) => {
      state.defects = action.payload
      state.defectsLoading = false
    })
    builder.addCase(getDefects.rejected, state => {
      state.defectsLoading = false
    })
  },
})

export const {
  postNewTrack,
  deleteTrack,
  postNewConstruction,
  deleteConstruction,
  patchConstructionName,
  patchTrackName,
  patchDirectionName,
  deleteDirection,
  postNewDirection,
  postNewObservation,
  patchObservationName,
  deleteObservation,
  postNewDefect,
  patchDefectName,
  deleteDefect,
  postNewAdditionalMission,
  deleteAdditionalMission,
  patchAdditionnalMissionName,
  patchAdditionalMissionDuration,
  postNewMission,
  deleteMission,
  patchMission,
} = paramsSlice.actions

export default paramsSlice.reducer
