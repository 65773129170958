import { Chip } from '@mui/material'
import { MissionTC } from 'types'
import { useAppSelector } from 'utils'
import './MissionOrientation.scss'

interface Props {
  row: MissionTC
  technicalCenterId: string
  handleClickOrientation: (row: MissionTC, sens: string, motrice: 'principale' | 'secondaire') => () => void
  disabled: boolean
}

export default function MissionOrientation({
  row,
  technicalCenterId,
  handleClickOrientation,
  disabled,
}: Props) {
  const { technicalCenters } = useAppSelector(state => state.params)
  return (
    <div className="mission-orientation">
      <div className="flex-center" style={{ gap: '4px' }}>
        <span className="name">{`${row.rame} :`}</span>
        {technicalCenters.find(tc => tc.id === technicalCenterId)
      && technicalCenters.find(tc => tc.id === technicalCenterId).sens_depart.map(sens => (
        <Chip
          label={sens.libelle}
          key={sens.id}
          style={{ borderRadius: 4, cursor: 'pointer' }}
          color={row.orientation_motrice?.principale === sens.id ? 'primary' : 'default'}
          disabled={disabled}
          onClick={handleClickOrientation(row, sens.id, 'principale')}
        />
      ))}
      </div>
      {row.um && (
      <div className="flex-center" style={{ gap: '4px' }}>
        <span className="name">{`${row?.um_details || 'Unité motrice'} :`}</span>
        {technicalCenters.find(tc => tc.id === technicalCenterId)
        && technicalCenters.find(tc => tc.id === technicalCenterId).sens_depart.map(sens => (
          <Chip
            label={sens.libelle}
            key={sens.id}
            style={{ borderRadius: 4, cursor: 'pointer' }}
            color={row.orientation_motrice?.secondaire === sens.id ? 'primary' : 'default'}
            disabled={disabled}
            onClick={handleClickOrientation(row, sens.id, 'secondaire')}
          />
        ))}
      </div>
      )}
    </div>
  )
}
