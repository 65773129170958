import { Close } from '@mui/icons-material'
import { Button, TimeFormField } from 'components'
import moment from 'moment'
import { MissionStatus, MissionTC } from 'types'
import { useState } from 'react'
import { handlePatchMissionTC } from 'services'
import { useAppDispatch } from 'utils'
import { getMissionsTC } from 'reducers/missionTable/thunks'
import './ModalPatchMission.scss'

interface Props {
  handleClose: () => void
  mission: MissionTC
  centerId: string
  ordering: string
}

export default function ModalPatchMissionNPD({
  handleClose,
  mission,
  centerId,
  ordering,
}: Props) {
  const dispatch = useAppDispatch()
  const [startValue, setStartValue] = useState<moment.Moment | null>(
    mission.debut_npd ? moment(mission.debut_npd) : null,
  )
  const [endValue, setEndValue] = useState<moment.Moment | null>(
    mission.fin_npd ? moment(mission.fin_npd) : null,
  )

  const handleValidate = handlePatchMissionTC(
    centerId,
    mission.id,
    () => {
      handleClose()
      dispatch(getMissionsTC({ technicenterId: centerId, ordering }))
    },
    {
      ...(startValue ? { debut_npd: moment(startValue).format() } : {}),
      ...(endValue ? {
        fin_npd: moment(endValue).format(),
        statut: MissionStatus.validated,
      } : {}),
    },
  )

  return (
    <div className="modal-patch-mission">
      <div className="header">
        <h2>
          Ne pas déranger
        </h2>
        <button type="button" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <div className="content">
        <TimeFormField
          value={startValue}
          onChange={newValue => { setStartValue(newValue) }}
          label="Heure de dénbut"
        />
        <TimeFormField
          value={endValue}
          onChange={newValue => { setEndValue(newValue) }}
          label="Heure de fin"
        />
      </div>
      <div className="footer">
        <Button
          text={endValue ? 'Valider et modifier' : 'Modifier'}
          onClick={handleValidate}
        />
      </div>
    </div>
  )
}
