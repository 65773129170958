import { ReactElement } from 'react'
import Button from '@mui/material/Button'

import './Button.scss'

export enum ButtonStyle {
  primary = 'primary',
  secondary = 'secondary',
  light = 'light',
  borderLess = 'borderLess',
  menuBorderLess = 'menuBorderLess',
  menuSelected = 'menuSelected',
  white= 'white',
  transparent = 'transparent',
  success = 'success',
  danger = 'danger',
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  className?: string;
  fullWidth?: boolean
}

export default function SimpleButton({
  text, onClick, style = ButtonStyle.primary, disabled = false, className = '', startIcon = null, endIcon = null,
  fullWidth = false,
}: Props): ReactElement {
  return (
    <Button
      className={`simple-button button--${style}${disabled ? ' disabled' : ''} ${className}`}
      variant="contained"
      size="large"
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {startIcon}
      {text}
      {endIcon}
    </Button>
  )
}
