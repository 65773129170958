import { notificationsSignal } from 'components'
import { addNotification } from 'reducers/notifications'
import { store } from 'reducers/store'
import { NotificationMessage, MissionNotification } from 'types'
import { v4 as uuidv4 } from 'uuid'

function isMissionNotification(value: Omit<NotificationMessage, 'id'>): value is Omit<MissionNotification, 'id'> {
  const predicate = value as MissionNotification
  return predicate.numero !== undefined && predicate.mission !== undefined && predicate.origine !== undefined
  && predicate.timestamp !== undefined && predicate.destinataire !== undefined
}

export const handleNotificationMessage = (message: Omit<NotificationMessage, 'id'>) => {
  try {
    if (isMissionNotification(message)) {
      const messageId: string = uuidv4()
      const newMessage = { id: messageId, ...message }
      store.dispatch(addNotification(newMessage))
      notificationsSignal.value = [...notificationsSignal.value, newMessage]
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while handling notification message', e)
  }
}
