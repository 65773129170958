import { MissionRD, MissionRDProgress, ParamsDefects, ParamsObservations } from 'types'
import { useAppDispatch, useAppSelector } from 'utils'
import { handlePatchAdditionnalMissionsRD, handlePatchMissionRD } from 'services'
import { getMissionRD } from 'reducers/rd/thunks'
import DataPanel from './DataPanel'

interface Props {
  technicalCenterId: string
  mission: MissionRD
  missionProgress: MissionRDProgress
}

export default function MissionAdditionalData({ technicalCenterId, mission, missionProgress }: Props) {
  const dispatch = useAppDispatch()
  const { defects, observations } = useAppSelector(state => state.params)

  const handlePatchDefects = (newDefects: ParamsDefects[]) => handlePatchMissionRD(
    technicalCenterId,
    mission.id,
    { defauts_rd: newDefects.map(defaut => defaut.id) as unknown as ParamsDefects[] },
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const handlePatchObservations = (newObservations: ParamsObservations[]) => handlePatchMissionRD(
    technicalCenterId,
    mission.id,
    { observations_rd: newObservations.map(observation => observation.id) as unknown as ParamsObservations[] },
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const handlePatchAdditionalMissions = (
    newMissions: {id: string, libelle: string}[],
  ) => handlePatchAdditionnalMissionsRD(
    technicalCenterId,
    mission.id,
    mission?.missions_supplementaires.map(mis => ({ id: mis.id, done: !!newMissions.find(m => m.id === mis.id) })),
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  if (missionProgress === MissionRDProgress.notStarted) {
    return null
  }

  return (
    <div className="rd-mission-additional-data">
      <DataPanel
        title="Défauts constatés"
        details={mission?.defauts_rd.map(defaut => defaut.libelle).join(', ') || 'Aucun'}
        options={defects}
        selectedOptions={mission?.defauts_rd || []}
        handleValidate={handlePatchDefects}
      />
      <DataPanel
        title="Observations RD"
        details={mission?.observations_rd.map(observation => observation.libelle).join(', ') || 'Aucune'}
        options={observations}
        selectedOptions={mission?.observations_rd || []}
        handleValidate={handlePatchObservations}
      />
      <DataPanel
        title="Missions supplémentaires"
        details={mission?.missions_supplementaires.filter(m => m.done)
          .map(m => m.modele.libelle).join(', ') || 'Aucune'}
        options={mission?.missions_supplementaires.map(m => ({ id: m.id, libelle: m.modele.libelle })) || []}
        selectedOptions={mission?.missions_supplementaires.filter(m => m.done)
          .map(m => ({ id: m.id, libelle: m.modele.libelle })) || []}
        handleValidate={handlePatchAdditionalMissions}
        disabled={mission?.missions_supplementaires.length === 0}
      />
    </div>
  )
}
