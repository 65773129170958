import { createApiThunk } from 'utils'
import { post } from '@osrdata/app_core/dist/requests'

const getUsersFromIds = createApiThunk(
  'cerbere/getUsersFromId',
  (ids: string[]) => post('/cerbere/users/simple/', ids),
)

export {
  getUsersFromIds,
}
