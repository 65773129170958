/* eslint-disable max-len */
import { ReactElement, useEffect, useRef } from 'react'
import { GATEWAY_DOMAIN } from 'config/config'
import { handleNotificationMessage } from 'services'

interface Props {
  centerId: string
}

export default function NotificationsStream({
  centerId,
}: Props): ReactElement {
  const ws: any = useRef()

  useEffect(() => {
    if (centerId) {
      console.log('connect WS', centerId)
      ws.current = new WebSocket(
        `wss://${GATEWAY_DOMAIN.url}/r2d2/technicentres/${centerId}/connect/?_auth_token=${`${localStorage.getItem(
          'access_token',
        )}`}`,
      )
      ws.current.onopen = () => console.log('streamopen')
      ws.current.onmessage = (event: { data: any }) => {
        const newMessage = JSON.parse(event.data)
        handleNotificationMessage(newMessage)
      }
      ws.current.onclose = () => {
        console.log('close')
      }
    }

    return () => {
      if (ws.current) {
        ws.current.close()
      }
    }
  }, [centerId])

  return (
    <> </>
  )
}
