import moment from 'moment'
import './DayGapBadge.scss'

interface Props {
  time?: string;
}

export default function DayGapBadge({ time }: Props) {
  const isSameDay = moment(time).isSame(moment(), 'day')
  if (!time || isSameDay) return null
  const dayDiff = moment(time).dayOfYear() - moment().dayOfYear()
  return (
    <span className="day-gap-badge">
      J
      {dayDiff > 0 ? '+' : ''}
      {dayDiff}
    </span>
  )
}
