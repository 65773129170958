import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NotificationsState, MissionNotification } from 'types'

const initialState: NotificationsState = {
  notifications: [],
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<MissionNotification>) => {
      state.notifications.push(action.payload)
    },
  },
})

export const {
  addNotification,
} = notificationsSlice.actions

export default notificationsSlice.reducer
