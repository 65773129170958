import { useDraggable } from '@dnd-kit/core'
import { Delete, DragHandle } from '@mui/icons-material'
import terms from 'assets/terms'
import { TooltipButton } from 'components'
import { CSS } from '@dnd-kit/utilities'
import './TeamAgent.scss'

interface Props {
  id: string;
  agentName: string;
  teamId: string;
  handleDelete: () => void;
}

const localTerms = terms.Pages.Manage.teams
export default function TeamAgent({ id, agentName, teamId, handleDelete }: Props) {
  const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: { agentId: id, teamId },
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    ...(isDragging && {
      cursor: 'grabbing',
    }),
  }
  return (
    <div className="team-agent" ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <div>
        <DragHandle />
        <span>{agentName}</span>
      </div>
      <div data-no-dnd="true">
        <TooltipButton
          tooltipText={localTerms.removeMember}
          onClick={handleDelete}
          icon={<Delete />}
        />
      </div>
    </div>
  )
}
