import { Close } from '@mui/icons-material'
import { Button, TimeFormField } from 'components'
import moment from 'moment'
import { useState } from 'react'
import './ModalPatchMission.scss'

interface Props {
  title: string
  handleClose: () => void
  handleValidate: (newValue: moment.Moment) => () => void
  timeValue: moment.Moment
}

export default function ModalPatchMissionTCHour({
  title,
  handleClose,
  handleValidate,
  timeValue,
}: Props) {
  const [value, setValue] = useState<moment.Moment>(timeValue)
  return (
    <div className="modal-patch-mission">
      <div className="header">
        <h2>{title}</h2>
        <button type="button" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <div className="content">
        <TimeFormField
          value={value}
          onChange={newValue => { setValue(newValue) }}
          label="Heure"
        />
      </div>
      <div className="footer">
        <Button
          text="Valider les modifications"
          onClick={handleValidate(value)}
        />
      </div>
    </div>
  )
}
