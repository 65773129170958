import { useState } from 'react'
import terms from 'assets/terms'
import { ParamsTechnicalCenter } from 'types'
import './ConstructionsForm.scss'
import { Button, AddFieldInput } from 'components'
import { Add } from '@mui/icons-material'
import { handlePostNewConstruction } from 'services'
import ConstructionForm from './ConstructionForm'

interface Props {
  technicalCenter: ParamsTechnicalCenter;
}

const localTerms = terms.Pages.Params.technicalCenters
export default function ConstructionsForm({ technicalCenter }: Props) {
  const [addingConstruction, setAddingConstruction] = useState(false)

  const handlePost = handlePostNewConstruction(technicalCenter.id, () => setAddingConstruction(false))

  return (
    <div className="constructions-form">
      <h3>{localTerms.constructions}</h3>
      {technicalCenter.chantiers.map((construction, constructionIndex) => (
        <ConstructionForm
          key={construction.id}
          construction={construction}
          constructionIndex={constructionIndex}
          centerId={technicalCenter.id}
        />
      ))}
      {addingConstruction ? (
        <AddFieldInput
          title={localTerms.newConstruction}
          addTooltip={localTerms.addConstruction}
          cancelTooltip={localTerms.cancelConstruction}
          handleAdd={handlePost}
          handleDelete={() => () => setAddingConstruction(false)}
        />
      ) : (
        <Button
          className="add-construction-button"
          onClick={() => setAddingConstruction(true)}
          text={localTerms.addConstruction}
          startIcon={<Add />}
        />
      )}
    </div>
  )
}
