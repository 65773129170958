import { Box, Chip, Stack } from '@mui/material'
import moment from 'moment'
import { MissionRD } from 'types'

interface Props {
  mission: MissionRD
  displayNumber: boolean
  displayTitle: boolean
}

export default function MissionInfos({
  mission, displayNumber, displayTitle,
}: Props) {
  return (
    <Stack direction="column" spacing={6} alignItems="flex-start" bgcolor="white" p="8px" borderRadius="8px">
      <Stack spacing={2} width="100%">
        {displayNumber && (
          <Box>
            <Chip
              sx={{ height: '50px', width: '50px', fontSize: 18 }}
              label={mission?.numero}
              style={{ borderRadius: 4 }}
              color="primary"
            />
          </Box>
        )}

        {displayTitle && (
          <>
            <Stack color="#256EFF" fontSize={24}>
              <Box>
                {mission?.modele.libelle}
              </Box>
              <Box>
                {mission?.rame}
                {' '}
                {mission?.um ? `(${mission.um_details} UM)` : ''}
              </Box>
              <Box>
                {mission?.voie_depart?.libelle}
                {mission?.voie_depart ? ' > ' : ''}
                {mission?.voie_arrivee?.libelle}
              </Box>
              <Box>
                {mission?.sens_depart?.libelle}
              </Box>
            </Stack>
            <Box>
              {mission?.chantier_depart?.libelle}
              {' > '}
              {mission?.chantier_arrivee?.libelle}
            </Box>
          </>
        )}

        <Stack>
          <Box>
            Sens de départ :
            {' '}
            <strong>{mission?.sens_depart ? mission.sens_depart.libelle : 'Non renseigné'}</strong>
          </Box>
          <Box>
            Via :
            {' '}
            <strong>{mission?.via ? mission.via : 'Non renseigné'}</strong>
          </Box>
          <Box>
            Début de mission théorique :
            {' '}
            <strong>{moment(mission?.heure_debut_theorique).format('HH:mm')}</strong>
          </Box>
        </Stack>
        <Stack>
          <Box>
            <strong>Missions supplémentaires</strong>
          </Box>
          <Box>
            {mission?.missions_supplementaires.length !== 0
              ? mission?.missions_supplementaires.map((ms, index) => (
                <span key={ms.id}>
                  {ms.modele.libelle}
                  {index < mission.missions_supplementaires.length - 1 && ', '}
                </span>
              )) : 'Pas de mission supplémentaire'}
          </Box>

        </Stack>
        <Stack>
          <Box>
            <strong>Observations COM</strong>
          </Box>
          <Box>
            {mission?.observation_com ? mission.observation_com : 'Pas d‘observation'}
          </Box>
        </Stack>
        <Stack>
          <Box>
            <strong>Pancartage</strong>
          </Box>
          <Box>
            {mission?.pancartage ? mission.pancartage : 'Pas de pancartage'}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
