import React from 'react'
import './TextFormField.scss'

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  disabled?: boolean;
  required?: boolean;
  name: string;
}

export default function TextFormField({
  value,
  onChange,
  label,
  disabled,
  required,
  name,
}: Props) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  return (
    <div className="text-form-field">
      <span>
        {label}
        {required && <span style={{ color: '#DA4238' }}>*</span>}
      </span>
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        autoComplete="off"
        disabled={disabled}
        name={name}
      />
    </div>
  )
}
