import { Delete } from '@mui/icons-material'
import terms from 'assets/terms'
import {
  Button, Loader, ModalAddUser, ModalConfirm, TooltipButton, modalSignal,
} from 'components'
import { useEffect } from 'react'
import { getConsultants } from 'reducers/manage/thunks'
import { useAppDispatch, useAppSelector, useCerbereUsers } from 'utils'
import { handleDeleteConsultant, handlePostNewConsultant } from 'services'
import './ConsultantTab.scss'

interface Props {
  technicalCenterId: string;
}

const localTerms = terms.Pages.Manage.consultants
export default function ConsultantTab({ technicalCenterId }: Props) {
  const dispatch = useAppDispatch()
  const { consultants, consultantsLoading } = useAppSelector(state => state.manage)
  const { getUserName, loadingUsers: usersLoading } = useCerbereUsers(consultants.map(com => com.cerbere_id))

  useEffect(() => {
    dispatch(getConsultants(technicalCenterId))
  }, [])

  const handleClickAdd = () => {
    modalSignal.value = (
      <ModalAddUser
        title={localTerms.add}
        placeholder={localTerms.searchPlaceholder}
        userLabel={localTerms.searchLabel}
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handlePostNewConsultant(technicalCenterId, () => { modalSignal.value = null })}
      />
    )
  }

  const handleClickDelete = (cerbereId: string) => () => {
    modalSignal.value = (
      <ModalConfirm
        title={localTerms.confirmDelete}
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleDeleteConsultant(technicalCenterId, cerbereId, () => { modalSignal.value = null })}
      />
    )
  }

  if (consultantsLoading || usersLoading) {
    return (
      <Loader message={localTerms.loading} standalone />
    )
  }
  return (
    <div className="consultant-tab">
      {consultants.map(com => (
        <div key={com.cerbere_id} className="item">
          <span>
            {getUserName(com.cerbere_id)}
          </span>
          <TooltipButton
            tooltipText={localTerms.delete}
            onClick={handleClickDelete(com.cerbere_id)}
            icon={(<Delete />)}
          />
        </div>
      ))}
      <Button text={localTerms.add} onClick={handleClickAdd} />
    </div>
  )
}
