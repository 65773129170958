import { ReactElement, StrictMode, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import '@preact/signals-react/auto'
import { auth } from '@osrdata/app_core'
import { ACCESS_PERM, APP_NAME, useAppDispatch, useAppSelector } from 'utils'
import { terms } from 'assets/terms'
import { hideSnackbar, setUserRoles } from 'reducers/app'
import {
  Loader, TopBar, SnackMessage, SideMenu, ModalWrapper, modalSignal,
  NotificationsWrapper,
} from 'components'
import {
  DeniedPage, HomePage, ParamsPage, ManagePage, MissionTablePage, RdPage,
  RdMissionsList,
  RdMissionDetail,
  // RdMissionInprogress,
} from 'pages'

import 'App.scss'
import { routes } from 'services'
import { get } from '@osrdata/app_core/dist/requests'
import { AppRoles, ParamsTechnicalCenter, UserRoles } from 'types'

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  const location = useLocation()

  useEffect(() => {
    // force hiding modal on route change
    modalSignal.value = undefined
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<HomePage />} path={routes.home} />
      <Route element={<HomePage />} path={routes.homeAlt} />
      <Route element={<ParamsPage />} path={routes.params} />
      <Route element={<ManagePage />} path={routes.manage} />
      <Route element={<MissionTablePage />} path={routes.missionTable} />
      <Route element={<RdPage />} path={routes.rd} />
      <Route element={<RdMissionsList />} path={`${routes.rd}${routes.rdMissionsList}/:statut`} />
      <Route
        element={<RdMissionDetail />}
        path={`${routes.rd}${routes.rdMissionsList}/:statut/:id`}
      />
      <Route path="*" element={<Navigate to={routes.home} />} />
    </Routes>
  )
}

const rolesMap = {
  COM: AppRoles.com,
  GESTIONNAIRE: AppRoles.manager,
  CONSULTANT: AppRoles.consultant,
  RD: AppRoles.RD,
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, appPermissions, isLoading } = useAppSelector(state => state.user)
  const { displaySnackbar, snackbarMessage, snackbarSeverity, loadingUserRoles } = useAppSelector(state => state.app)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0 && !appPermissions.includes(ACCESS_PERM)) {
      setAccessDenied(true)
    } else if (isLogged && appPermissions.includes(ACCESS_PERM)) {
      get('/r2d2/technicentres').then((tcList : ParamsTechnicalCenter[]) => {
        const userRoles : UserRoles[] = []
        tcList.forEach(tc => {
          const tcName = tc.libelle.replace(' ', '_')
          const roles = appPermissions.filter(p => p.includes(tcName) && p.split('::')[0] === 'r2d2')
            .map(r => r.split('::')[1]).filter(r => r in rolesMap).map(r => rolesMap[r])
          if (roles.length > 0) {
            userRoles.push({
              id: tc.id,
              libelle: tc.libelle,
              // If you have COM and consultant, you are COM
              roles: roles.filter(r => (r === AppRoles.consultant && !roles.includes(AppRoles.com))
              || r !== AppRoles.consultant),
            })
          }
        })
        dispatch(setUserRoles(userRoles))
      })
    }
  }, [isLogged, appPermissions, isLoading])

  const renderApp = () => {
    if (accessDenied) return <DeniedPage />
    if (isLoading || loadingUserRoles) return <Loader message={terms.Common.loading} standalone />

    return (
      <>
        <Router />
        <ModalWrapper />
        <SideMenu />
        <NotificationsWrapper />
      </>
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          {!isLoading && <TopBar enableSideMenu={false} appName={APP_NAME} hasAccess={!accessDenied} />}
          <div id="app">{renderApp()}</div>
          <SnackMessage
            message={snackbarMessage}
            severity={snackbarSeverity}
            displaySnackbar={displaySnackbar}
            handleClose={() => dispatch(hideSnackbar())}
          />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
