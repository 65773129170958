import terms from 'assets/terms'
import AddFormWrapper from 'components/addForms/AddFormWrapper'
import { useState } from 'react'
import { ParamsMission } from 'types'
import MissionBooleanFields from './MissionBooleanFields'
import './AddMissionForm.scss'

interface Props {
  handleAddMission: (mission: Omit<ParamsMission, 'id'>) => () => void
  handleCancel: () => void
}

const localTerms = terms.Pages.Params.missions
export default function AddMissionForm({
  handleAddMission, handleCancel,
}: Props) {
  const [mission, setMission] = useState<Omit<ParamsMission, 'id'>>({
    libelle: '',
    duree_theorique: 0,
    chantier_depart: true,
    voie_depart: true,
    chantier_arrivee: true,
    voie_arrivee: true,
    sens_depart: true,
    via: true,
  })

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMission({ ...mission, libelle: e.target.value })
  }

  const handleChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMission({ ...mission, duree_theorique: e.target.value as unknown as number })
  }

  const handleClickField = (field: string) => () => {
    if (
      !['chantier_depart', 'voie_depart', 'chantier_arrivee', 'voie_arrivee', 'sens_depart', 'via'].includes(field)
    ) return
    setMission({
      ...mission,
      [field]: !mission[field],
      ...(field === 'chantier_depart' && mission.chantier_depart && mission.voie_depart && { voie_depart: false }),
      ...(field === 'voie_depart' && !mission.chantier_depart && !mission.voie_depart && { chantier_depart: true }),
      ...(field === 'chantier_arrivee' && mission.chantier_arrivee && mission.voie_arrivee && { voie_arrivee: false }),
      ...(field === 'voie_arrivee' && !mission.chantier_arrivee && !mission.voie_arrivee && { chantier_arrivee: true }),
    })
  }

  return (
    <AddFormWrapper
      className="add-mission-form"
      addTooltip={localTerms.add}
      cancelTooltip={localTerms.cancel}
      handleAdd={handleAddMission(mission)}
      handleDelete={handleCancel}
    >
      <div className="form">
        <div className="text-fields">
          <div className="input">
            <span>{localTerms.label}</span>
            <input
              type="text"
              value={mission.libelle}
              onChange={handleChangeName}
            />
          </div>
          <div className="input">
            <span>{localTerms.duration}</span>
            <input
              type="number"
              value={mission.duree_theorique}
              onChange={handleChangeDuration}
            />
          </div>
        </div>
        <MissionBooleanFields id="new-mission" mission={mission} handleClickField={handleClickField} />
      </div>
    </AddFormWrapper>

  )
}
