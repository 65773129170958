import { ArrowForwardIos } from '@mui/icons-material'
import './ButtonSelect.scss'

interface Props {
  text: string;
  onClick?: () => void;
}

export default function ButtonSelect({ text, onClick }: Props) {
  return (
    <button
      className="button-select"
      onClick={onClick}
      type="button"
    >
      <span>{text}</span>
      <ArrowForwardIos />
    </button>
  )
}
