import { useAppSelector } from 'utils'
import './Tabs.scss'
import { AppRoles } from 'types'
import { PeopleOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { ModalManageTeam, modalSignal } from 'components/modals'

export default function Tabs() {
  const { activeRole } = useAppSelector(state => state.app)
  const { activeTeams, activeAgentsCount, teams, agents } = useAppSelector(state => state.missionTable)

  const handleClickTeamButton = () => {
    if (activeRole.role !== AppRoles.com) return
    modalSignal.value = (
      <ModalManageTeam
        technicenterId={activeRole.technicenterId}
        technicenterTeams={teams}
        technicenterAgents={agents}
        handleClose={() => { modalSignal.value = null }}
      />
    )
  }

  return (
    <div className="topbar-tabs flex-center">
      {activeRole?.role === AppRoles.com && (
        <button
          type="button"
          className="flex-center team-button"
          onClick={handleClickTeamButton}
        >
          <PeopleOutlined />
          <div className="infos">
            <strong>Equipe(s)</strong>
            {activeTeams.length === 0 || activeAgentsCount === 0 ? (
              <span>Aucune équipe active</span>
            ) : (
              <Tooltip title={`${activeTeams.join(', ')} - ${activeAgentsCount} agent(s)`}>
                <span>{`${activeTeams.join(', ')} - ${activeAgentsCount} agent(s)`}</span>
              </Tooltip>
            )}
          </div>
        </button>
      )}
    </div>
  )
}
