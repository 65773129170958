import { get } from '@osrdata/app_core/dist/requests'
import { MissionRD } from 'types'
import { createApiThunk } from 'utils'

const getAffectedMissionsRD = createApiThunk(
  'r2d2/getAffectedMissionsRD',
  (technicenterId: string) => get<MissionRD[]>(`/r2d2/technicentres/${technicenterId}/missions/affectee/`),
)

const getInProgressMissionsRD = createApiThunk(
  'r2d2/getInProgressMissionsRD',
  (technicenterId: string) => get<MissionRD[]>(`/r2d2/technicentres/${technicenterId}/missions/encours/`),
)

const getMissionRD = createApiThunk(
  'r2d2/getMissionRD',
  ({ technicenterId, idMission }: { technicenterId: string; idMission: string }) => get<MissionRD>(
    `/r2d2/technicentres/${technicenterId}/missions/${idMission}/`,
  ),
)

export {
  getAffectedMissionsRD,
  getInProgressMissionsRD,
  getMissionRD,
}
