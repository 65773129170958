import { Add, Delete } from '@mui/icons-material'
import terms from 'assets/terms'
import { useState } from 'react'
import { AsyncInput, Button, AddFieldInput, TooltipButton } from 'components'
import { ParamsTechnicalCenter } from 'types'
import {
  handleChangeDirectionName, handleDeleteDirection, handlePostNewDirection,
} from 'services'
import './DirectionsForm.scss'

interface Props {
  technicalCenter: ParamsTechnicalCenter;
}

const localTerms = terms.Pages.Params.technicalCenters
export default function DirectionsForm({ technicalCenter }: Props) {
  const [addingDirection, setAddingDirection] = useState(false)
  return (
    <div className="directions-form">
      <h3>{localTerms.direction}</h3>
      {technicalCenter.sens_depart.map((direction, directionIndex) => (
        <div className="direction-item" key={direction.id}>
          <span className="title">
            {`${localTerms.directionTitle}${directionIndex + 1}`}
          </span>
          <div className="direction-input">
            <AsyncInput
              value={direction.libelle}
              handleChangeCallback={handleChangeDirectionName(technicalCenter.id, direction.id)}
            />
            <TooltipButton
              icon={<Delete />}
              tooltipText={localTerms.deleteDirection}
              onClick={handleDeleteDirection(technicalCenter.id, direction.id)}
            />
          </div>
        </div>
      ))}
      {addingDirection ? (
        <AddFieldInput
          title={localTerms.newDirection}
          addTooltip={localTerms.newConstruction}
          cancelTooltip={localTerms.cancelDirection}
          handleAdd={handlePostNewDirection(technicalCenter.id, () => setAddingDirection(false))}
          handleDelete={() => () => setAddingDirection(false)}
        />
      ) : (
        <Button
          className="add-direction-button"
          onClick={() => setAddingDirection(true)}
          text={localTerms.addDirection}
          startIcon={<Add />}
        />
      )}
    </div>
  )
}
