import { CerbereUser } from 'types'
import { useState } from 'react'
import UserSearch from 'components/userSearch/UserSearch'
import terms from 'assets/terms'
import './ModalAddUser.scss'
import { Button, ButtonStyle } from 'components'

interface Props {
  title: string;
  placeholder: string;
  userLabel: string;
  handleClose: () => void;
  handleValidate: (selectedUser: CerbereUser) => () => void;
}

export default function ModalAddUser({
  title, placeholder, userLabel, handleClose, handleValidate,
}: Props) {
  const [selectedUser, setSelectedUser] = useState<CerbereUser | null>(null)

  const handleSelectUser = (user: CerbereUser) => {
    setSelectedUser(user)
  }

  return (
    <div className="modal-add-user">
      <h3>{title}</h3>
      <UserSearch
        placeholder={placeholder}
        label={userLabel}
        size="medium"
        handleSelectUser={handleSelectUser}
        required
      />
      <div className="actions">
        <Button
          text={terms.Common.cancel}
          onClick={handleClose}
          style={ButtonStyle.light}
        />
        <Button
          text={terms.Common.save}
          onClick={handleValidate(selectedUser)}
          disabled={!selectedUser}
        />
      </div>
    </div>
  )
}
