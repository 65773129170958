import { Box, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button, ButtonStyle,
} from 'components'
import { routes } from 'services'
import { AppRoles } from 'types'
import { useAppDispatch, useAppSelector, usePageRoles } from 'utils'
import { getAffectedMissionsRD, getInProgressMissionsRD } from 'reducers/rd/thunks'
import { getDefects, getObservations } from 'reducers/params/thunks'

export default function RdPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  usePageRoles([AppRoles.RD])
  const { activeRole } = useAppSelector(state => state.app)
  const { missionsRD, inProgressMissionsRD } = useAppSelector(state => state.rd)

  useEffect(() => {
    if (activeRole && activeRole.role !== AppRoles.admin) {
      dispatch(getAffectedMissionsRD(activeRole.technicenterId))
      dispatch(getInProgressMissionsRD(activeRole.technicenterId))
      dispatch(getDefects())
      dispatch(getObservations())
    }
  }, [activeRole])

  return (
    <Box sx={{ background: '#F5F9FF', margin: '12px', padding: '12px', height: 'calc(100% - 24px)' }}>
      <Stack spacing={4}>
        <Box width="100%" display="flex" justifyContent="center" fontSize={20}>
          Menu
        </Box>
        <Button
          fullWidth
          text={`${missionsRD.filter(mission => mission.statut === 'AFFECTEE').length} mission(s) affectée(s)`}
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/affectee`)
          }}
          style={ButtonStyle.primary}
          disabled={missionsRD.filter(mission => mission.statut === 'AFFECTEE').length === 0}
        />
        <Button
          fullWidth
          text={`${inProgressMissionsRD.length} mission(s) en cours`}
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/inprogress`)
          }}
          style={ButtonStyle.primary}
          disabled={inProgressMissionsRD.length === 0}
        />
        <Button
          fullWidth
          text="Missions supprimées / refusées"
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/refusee`)
          }}
          style={ButtonStyle.primary}
          disabled={missionsRD.filter(mission => mission.statut === 'REFUSEE'
            || mission.statut === 'SUPPRIMEE').length === 0}

        />
        <Button
          fullWidth
          text="Missions validées"
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/validee`)
          }}
          style={ButtonStyle.primary}
          disabled={missionsRD.filter(mission => mission.statut === 'VALIDEE').length === 0}
        />
      </Stack>
    </Box>
  )
}
