import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CerbereState, CerbereUser } from 'types'
import { getUsersFromIds } from './thunks'

const initialState: CerbereState = {
  users: [],
  loadingUsers: false,
  unknownUsers: [],
}

export const cerbereSlice = createSlice({
  name: 'cerbere',
  initialState,
  reducers: {
    setCerbereUsers: (state, action: PayloadAction<CerbereUser[]>) => {
      state.users = action.payload
    },
    addCerbereUser: (state, action: PayloadAction<CerbereUser>) => {
      state.users = [
        ...state.users.filter(user => user.id !== action.payload.id),
        action.payload,
      ]
    },
  },
  extraReducers: builder => {
    builder.addCase(getUsersFromIds.pending, (state, action) => {
      state.loadingUsers = true
      const newUsers: CerbereUser[] = action.meta.arg.map((id: string) => ({
        id,
        username: '',
        firstName: '',
        lastName: '',
        displayName: '',
      }))
      state.users = [...state.users.filter(user => !action.meta.arg.includes(user.id)), ...newUsers]
    })
    builder.addCase(getUsersFromIds.fulfilled, (state, action) => {
      state.loadingUsers = false
      state.users = [...state.users.filter(user => !action.meta.arg.includes(user.id)), ...action.payload]
      state.unknownUsers = [
        ...state.unknownUsers.filter(id => !action.meta.arg.includes(id)),
        ...action.meta.arg.filter(id => !action.payload.map(user => user.id).includes(id)),
      ]
    })
    builder.addCase(getUsersFromIds.rejected, state => {
      state.loadingUsers = false
    })
  },
})

export const {
  setCerbereUsers,
  addCerbereUser,
} = cerbereSlice.actions

export default cerbereSlice.reducer
