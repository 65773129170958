import { Button, ButtonStyle } from 'components'
import { v4 as nextId } from 'uuid'
import './Filter.scss'

interface Props<T> {
  title: string
  handleClickAll: () => void
  allSelected: boolean
  items: T[]
  handleClick: (item: T) => () => void
  itemSelected: (item: T) => boolean
  getText: (item: T) => string
}

export default function Filter<T>({
  title, handleClickAll, allSelected, handleClick, items, itemSelected, getText,
}: Props<T>) {
  return (
    <div className="mission-filter">
      <h2>{title}</h2>
      <div className="buttons">
        <Button
          text="Tout"
          onClick={handleClickAll}
          style={allSelected ? ButtonStyle.primary : ButtonStyle.secondary}
        />
        {items.map(item => (
          <Button
            key={nextId()}
            text={getText(item)}
            onClick={handleClick(item)}
            style={itemSelected(item)
              ? ButtonStyle.primary : ButtonStyle.secondary}
          />
        ))}
      </div>
    </div>
  )
}
